
import React, { useState, useEffect, Component, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  //Form,
  FormGroup,
  Label,
  Spinner,
  /* Alert, */
  Button
} from "reactstrap";
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
//import useForm from "./useForm";
import InputGroup from 'react-bootstrap/InputGroup';
import Resizer from "react-image-file-resizer";
import {  Card,   CardBody } from "reactstrap";
import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";
import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
// import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
// import Alert from 'react-bootstrap/Alert';
import { useForm } from "react-hook-form";
import { render } from "react-dom";
//import TextareaAutosize from "react-textarea-autosize";

import {Alert} from "reactstrap";
import AWS from "aws-sdk";
import '@fortawesome/fontawesome-free/css/all.min.css';


class CompanyDocumentManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            name: '',
            company: '',
            loggedInUser: '',
            s3Objects: [],
            searchQuery: '',
            showAlert: false,
            alertMessage: '',
            
        };

        this.fileInputRef = React.createRef();

    }

    componentDidMount() {
        this.fetchS3Objects();
    }

    fetchS3Objects = () => {
        // Configure AWS SDK
        AWS.config.update({
            accessKeyId: 'AKIAUQ62QJHPG6KLKGPX',
            secretAccessKey: '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', 
            region: 'us-east-1',
            correctClockSkew: true
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: 'nelbucket-456',
        };

        s3.listObjectsV2(params, (err, data) => {
            if (err) {
                console.log("Error fetching objects from S3: ", err);
            } else {
                console.log("Fetched objects: ", data.Contents);
                this.setState({ s3Objects: data.Contents });
            }
        });
    }

    viewS3Object = (objectKey) => {
        const s3 = new AWS.S3();
        const params = {
            Bucket: 'nelbucket-456',
            Key: objectKey,
        };

        const fileType = objectKey.split('.').pop().toLowerCase();
        console.log("fileType: ", fileType);

        if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
            s3.getSignedUrl('getObject', params, (err, url) => {

                if (err) {
                    console.error("Error generating pre-signed URL: ", err);
                } else {
                    this.setState({ selectedFileContent: <img src={url} style={{ width: '100%', height: '500px', border: 'none' }} alt="Image Document" /> });
                }
            })
        } else {
            s3.getObject(params, (err, data) => {
                if (err) {
                    console.error("Error getting object from S3: ", err);
                } else {
                    console.log("Object fetched successfully: ", data);
        
                    const pdfBlob = new Blob([data.Body], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(pdfBlob);
        
                    this.setState({ selectedFileContent: <iframe src={pdfUrl} style={{ width: '100%', height: '500px', border: 'none' }} title="PDF Document"></iframe> });
                }
            });
        }

    }

    handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        console.log("Selected file:", selectedFile);

        // Update the state with the selected file
        this.setState({ file: selectedFile });
    };

    uploadFile = () => {
        const { file } = this.state;

        // Configure AWS SDK
        const S3_BUCKET = 'nelbucket-456';
        const REGION = 'us-east-1';
        const ACCESS_KEY = 'AKIAUQ62QJHPG6KLKGPX';
        const SECRET_KEY = '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';

        AWS.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_KEY,
            region: REGION,
            correctClockSkew: true
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: S3_BUCKET,
            Key: file.name,
            Body: file,
            ACL: 'bucket-owner-full-control',
        };

        s3.upload(params, (err, data) => {
            if (err) {
                console.log("Error uploading file:", err);
                alert("Error uploading file.");
            } else {
                console.log("Successfully uploaded file:", data);
                this.setState({
                    showAlert: true,
                    alertMessage: "File uploaded successfully!",
                    rname: "",
                    rcode: "",
                    isInactive: false,
                    selectedRoleId: null
                });

                this.fileInputRef.current.value = null;

                setTimeout(() => {
                    this.setState({ showAlert: false });
                }, 3000);
                this.fetchS3Objects();
            }
        });
    };

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    };


    render () {
        const { s3Objects, searchQuery, selectedFileContent, name, company, showAlert, alertMessage } = this.state;

        const filteredS3Objects = this.state.s3Objects.filter(object =>
            object.Key.toLowerCase().includes(this.state.searchQuery.toLowerCase())
        );

        return (
            <Container fluid className="vh-100">
                {showAlert && (
                    <Alert variant="success" onClose={() => this.setState({ showAlert: false })} dismissible>
                        {alertMessage}
                    </Alert>
                )}
                <NavBar />
                <Row className="mt-3"></Row>
                   <Row className="mt-3"></Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Label style={{ marginTop: '20px', fontSize: '30px', color: 'green', textTransform: 'none' }}>Company Document Management</Label>
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                        <Label style={{ fontSize: '15px', color: '#7a7777', color: "#056c1d" }}>
                            Welcome!
                        </Label>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Button style={{ backgroundColor: 'green', height: "auto" }}>Create Folder</Button>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={6}>
                        <div style={{ position: 'relative', width: '300px' }}>
                            <input 
                                type="text"
                                name="browse"
                                style={{ 
                                    height: "40px", 
                                    width: "450px", 
                                    paddingLeft: "30px", 
                                    border: "1px solid #C1BEBE", 
                                    borderRadius: "3px" }}
                                    placeholder='Search document here...'
                                value={searchQuery}
                                onChange={this.handleSearchChange}
                            />
                            <i className="fas fa-search" 
                                style={{
                                    position: 'absolute',
                                    left: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    color: '#C1BEBE'
                                }}>
                            </i>
                        </div>
                    </Col>
                    <Col md={6}>
                        <input
                            type="file"
                            ref={this.fileInputRef}
                            style={{ 
                                marginTop: "0px", 
                                marginLeft: "10px", 
                                cursor: "pointer", 
                                width: "480px",
                                height: "45px",
                                paddingTop: "10px",
                                paddingLeft: "10px",
                                background: "#DFFCEAFF",
                                // border: "1px solid #7a7777", 
                                borderRadius: "2px",
                                display: "flex",
                                alignItems: "center",
                                verticalAlign: "middle",
                                float: "left"
                            }}
                            onChange={this.handleFileChange}
                        />
                        <Button style={{ backgroundColor: 'green', height: "auto", marginLeft: "10px" }} onClick={this.uploadFile} >Upload</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <Label style={{ fontSize: '18px', color: '#7a7777' }}>Choose document to be viewed:</Label>
                        <div
                            style={{ 
                                border: "1px solid #C1BEBEFF", 
                                height: "auto", 
                                overflow: "hidden" , 
                                borderRadius: "5px", 
                                paddingTop: "10px" }}>
                            
                            <ul>
                                {/* Render filtered documents based on search query */}
                                {filteredS3Objects.map((object, index) => (
                                    <li key={index} onClick={() => this.viewS3Object(object.Key)}>
                                        {object.Key}
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </Col>
                    <Col md={9}>
                        <Label style={{ fontSize: '18px', color: '#7a7777', textTransform: 'none' }}>View your document here:</Label>
                        <div className="role-form" style={{ height: "auto", border: "1px solid #C1BEBEFF", borderRadius: "5px", }}>
                            {selectedFileContent || ""}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

}

export default CompanyDocumentManagement;