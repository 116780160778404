import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Row, Input, Label, select, Text, Modal, ModalBody, Alert} from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment';

import { AppConfiguration } from "read-appsettings-json";
import { GetRequest, PostRequest } from '../../../noser-dataaccess'
import axios from 'axios'
import dateFormat from 'dateformat';
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Switch from "react-switch";


const ApplyCompProf = () => {
  const navigate = useNavigate();
  const navigateSignIn = useNavigate();
  const navigateSignUp = useNavigate();
  const navigateCandidProf = useNavigate();
  const navigateSuccScreen = useNavigate();


  const [applydisbled, setApplyDsbld] = useState(true);
  const [submitResume, setModalResume] = useState(false);
  const [checkDisable, setcheckDisable] = useState(true);
  const openModalResume = () => setModalResume(!submitResume);
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [modalOtp, setModalOtp] = useState(false);
  const openModalOtp = () => setModalOtp(!modalOtp);
  const [modalScreen1, setfrstModal] = useState(false);
  const openfrstModal = () => setfrstModal(!modalScreen1);
  const [modalScreen2, setScndModal] = useState(false);
  const openScndModal = () => setScndModal(!modalScreen2);
  const [modalScreen3, setThrdModal] = useState(false);
  const openThrdModal = () => setThrdModal(!modalScreen3);
  const [applybyresume, setModalApplyByResume] = useState(false);
  const openModalApplyResume = () => setModalApplyByResume(!applybyresume);

  /* Job Details */
  const [jobDetails, setJobDetails] = useState([])
  const [jobPostingId, setJPSession] = useState(JSON.parse(sessionStorage.getItem("key")));
  const [idSlctd, setKeySession] = useState(JSON.parse(sessionStorage.getItem("companyId")));
  const [userCol, setUserCol] = useState();
  const [jobDesc, setJobDiscription] = useState("")
  const [skillAndExp, setSkillExpr] = useState([])
  const [responsibility, setResponsibilities] = useState([])
  const [qualifications, setQualifications] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [title, setTitle] = useState("")
  const [companyNumber, setCompNumber] = useState("")
  const [companyEmail, setCompEmail] = useState("")
  const [companyWebsite, setCompPage] = useState("")
  const [companyLocation, setCompanyLocation] = useState("")
  const [landMark, setLocation] = useState("")
  const [employeeIdTmp, setEmployeeId] = useState("")

  /* Job Overview Details */
  const [jobType, setJobType] = useState("")
  const [category, setCategory] = useState("")
  const [jobOverviewLocation, setJobOverviewLocation] = useState("")
  const [offeredSalary, setOfferedSalary] = useState("")
  const [posted, setPosted] = useState("")
  const [experience, setExperience] = useState("")
  const [gender, setGender] = useState("0")
  const [industry, setIndustry] = useState("")
  const [qualification, setQualification] = useState("")
  const [level, setLevel] = useState("")
  const [applied, setApplied] = useState("")
  const [applicationDate, setApplicationDate] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [message, setMessage] = useState("")

  // drop down job overview
  const [_jobDepartment, setJobDepartment] = useState("")
  const [_jobEmploymentType, setEmploymentType] = useState("")
  const [_jobExprnc, setJobExp] = useState("")
  const [_jobQualification, setJobQuali] = useState("")
  const [_jobEducLvl, setJobEducLvl] = useState("")
  const [jobDepartment, setjobDept] = useState([])
  const [employementType, setApplicantType] = useState([])
  const [educLevel, setEdLevel] = useState([])
  const [empJobType, setEmpJobType] = useState([])
  const [jobDetailsPostingId, setPostingJobId] = useState("")
  const [validate, setValidate] = useState("")
  const [emailAdd, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [contactNo, setContactNo] = useState("") 
  const [dataPrivacy, setDataPrivacy] = useState("N")
  const [btnDIsbld, setButtons] = useState(true)
  const [isDataprivacy, setisDataprivacy] = useState(true)
  const [filesResume, setFilePdf] = useState("") 
  const [mdlDataPrvcy, setOpenModalPrivcy] = useState(false)
  const [values, setValues] = useState({
    one             : "",//
    two          : "",//
    three              : "",
    four           : "",//
    five           : "",
    six           : "",
  });
  const [empId, setEmpId] = useState("") 
  const [verified, setVerified] = useState("0") 
  const [isCheckbox, setCheckbox] = useState(true) 
  const [isCompleted, setDataRsitrct] = useState("") 
  const [_createdDate, setCreatedDate] = useState("") 
  const [_endDate, setEnddate] = useState("") 
  const [numberApplcants, SetNumApplicants] = useState("") 
  const [ date , setDate] = useState("")
  const [expRequired, setExpRequired] = useState("") 
  const [workingDays, setWorkingDays] = useState("") 
  const [errorMessage, setErrorMsg] = useState("") 
    
  /* Message */
  const [isshow, setisshow] = useState("")
  const [color, setcolor] = useState("")
  const [messageAlert, setmessagealert] = useState("")
  const [fade, setfade] = useState("")
  const [alerttype, setalerttype] = useState("")

  useEffect(() => {
    GetCompanyProfile()
    setDate(new Date())
    const isValidate = JSON.parse(sessionStorage.getItem("userCollection"))
    if (isValidate === null){
      setValidate("0")
      setUserCol("")
      setEmployeeId("")
      GetTestRestrict()
    }
    else {
      setValidate("1")
      setUserCol(isValidate[0].userId)
      setEmployeeId(isValidate[0].userId)
      GetTestRestrict(isValidate[0].userId)
      let fullNameTmp = isValidate[0].firstName + " " + isValidate[0].lastName
      setEmail(isValidate[0].email)
      setFullName(fullNameTmp)
      setContactNo(isValidate[0].contact)
      GetTestRestrict(isValidate[0].userId)
    }
    // console.log(jobPostingId)
    // console.log(idSlctd)
  },[]);
  const GetCompanyProfile = async() => {
    let filter_data = {"isDeleted": "0"}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.CompanyProfile.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        dataTmp.push(json)
      })       
        
      // setJson(dataTmp)  
      GetJobDescription(dataTmp); 
      // console.log("dataTmp")
      // console.log(dataTmp)
    })
  };
  const GetJobDescription =async(_dataList)=> {
    // console.log("jobPostingId")
    // console.log(jobPostingId)
    let jobPosting_ = ""
    try{
      let resp = await GetRequest({
        url : AppConfiguration.Setting().mongoJobspace + "action/get",
        params : {
          "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
          "_collection" : "JobPosting",
          "filter_data" : {}
        }
      })
      console.log("resp.JobPosting")
      console.log(jobPostingId)
      if(resp.status==="1"){
        const jobPostingList = resp.JobPosting.filter(x=>x.id===jobPostingId)
        const compProfList = _dataList.filter(x => jobPostingList.some(itm => itm.companyId === x.userId))
        // console.log("jobPostingList")
        // console.log(jobPostingList)
        // // console.log("resp.JobPosting")
        // // console.log(resp.JobPosting)
        setJobDiscription(jobPostingList[0].description)
        setTitle(jobPostingList[0].title)
        setSkillExpr(jobPostingList[0].skillsExperience)
        setResponsibilities(jobPostingList[0].responsibility)
        setQualifications(jobPostingList[0].qualifications)
        setJobDetails(resp.JobPosting.filter(x=>x.id===idSlctd))

        let compTmp = (compProfList[0].companyName).toLowerCase()
        let toCaseComp = toTitleCase(compTmp)
        setCompanyName(toCaseComp)
        setCompanyId(compProfList[0].id)
        setCompNumber(compProfList[0].contactNumberPhone)
        let emailTmp = (compProfList[0].email).toLowerCase()
        let toCaseEmail = toTitleCase(emailTmp)
        setCompEmail(toCaseEmail)
        let webTmp = (compProfList[0].website).toLowerCase()
        let toCaseWeb = toTitleCase(webTmp)
        setCompPage(toCaseWeb)
        let _location = /* compProfList[0].baranggay + " " +  */compProfList[0].city + " " + compProfList[0].country //+ " " + compProfList[0].locationStreet + " " + compProfList[0].locationCity + " " + compProfList[0].locationProvince + " " + compProfList[0].locationZipCode
        let lowerLoc = (_location).toLowerCase()
        let toCaseLoc = toTitleCase(lowerLoc)
        setCompanyLocation(toCaseLoc)
        let landMark = (compProfList[0].city).toLowerCase() //+ "," + " " + fltrData[0].locationProvince
        // let landMarkTmp = compProfList[0].email
        let toCaseLandmark = toTitleCase(landMark)
        setLocation(toCaseLandmark)
        let applicationDate = dateFormat(jobPostingList[0].endDate).split(" ")
        // let s1 = applicationDate[0]
        let s2 = applicationDate[1]
        let s3 = applicationDate[2]
        let s4 = applicationDate[3]
        let joinDate = s2 + " " + s3 + ", " + s4
        let spliAppEnd = joinDate
        // // console.log("compProfList[0]")
        // // console.log(compProfList[0])
        setApplicationDate(spliAppEnd)
        setExpRequired(jobPostingList[0].experienceMin)
        setWorkingDays(jobPostingList[0].workingDaysAndHrs)

        // let x1 = jobPostingList[0].jobDepartment
        // let x2 = jobPostingList[0].jobTime
        // let x3 = jobPostingList[0].applicantType
        // let x4 = jobPostingList[0].qualifications
        // let x5 = jobPostingList[0].educationalLevel

        // setJobDepartment(x1[0].department)
        // setEmploymentType(x2[0].department)
        // setJobExp(x3[0].ApplicantType)
        // setJobQuali(x4[0].skill)
        // setJobEducLvl(x5[0].educlvl)

        setJobDepartment(jobPostingList[0].dept)
        setEmploymentType(jobPostingList[0].workType)
        setJobExp(jobPostingList[0].aplicantExp)
        setOfferedSalary(jobPostingList[0].salaryMin)
        // setJobQuali(x4[0].skill)
        setJobEducLvl(jobPostingList[0].education)
        setPostingJobId(jobPostingList[0].postingId)
        setCreatedDate(jobPostingList[0].createdDate)
        setEnddate(jobPostingList[0].endDate)
        // // console.log("fltrData[0].city")
        // // console.log(fltrData[0].city)
        // jobPosting_ = jobPostingList[0].postingId
        GetNumOfApplicants(jobPostingList[0].postingId)
      }
      // // console.log()
      // // console.log()
    }
    catch(error){
      // setMessage(error.message)
      alert("error 1234: " + error.message)
    }
  };
  const GetNumOfApplicants = async(postingId) => {
    let filter_data = {"postingId" : postingId}
    const _dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "Candidates",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      data.Candidates.map(function(itm,i){
        const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
        const json = JSON.parse(strJson.replace(/'/g,'"'));
        _dataTmp.push(json)
      })
      // console.log("postingId")
      // console.log(postingId)
      SetNumApplicants(String(parseFloat(data.Candidates.length) + 1)); 
    })
  };
  const GetTestRestrict = async(empId) => {
    // // console.log("userCol")
    // // console.log(typeof userCol === "undefined")
    let fltrUser = ""
    // let _fltrUser = userCol.length===0 || userCol === null ? "" : userCol
    if(typeof empId === "undefined"){
      // console.log("if")
      fltrUser = ""
    }
    else{
      // console.log("else")
      fltrUser = empId
    }
    // console.log("empId")
    // console.log(empId)
    let filter_data = {"isDeleted": "0", "userId" : fltrUser}
    const dataTmp = []
    const Params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "TestEmployeeRistriction",
      "filter_data" : filter_data
    }
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {
      let data = res.data
      if(data.TestEmployeeRistriction.length === 0){
        setDataRsitrct("0"); 
      }
      else{
        // let getUserId = JSON.parse(sessionStorage.getItem("userCollection"))
        data.TestEmployeeRistriction.map(function(itm,i){
          const strJson = itm.replace("","").replace("ObjectId(","").replace(")","").replace("_","")
          const json = JSON.parse(strJson.replace(/'/g,'"'));
          dataTmp.push(json)
        })       
          
        // setJson(dataTmp)  
        setDataRsitrct(dataTmp[0].isCompleted); 
        // console.log("dataTmp")
        // console.log(dataTmp)
      }
    })
  };

  function toTitleCase(str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }
  
  const onChangeEmail =(e)=>{
    setEmail(e.target.value)
  }
  const onChangeFullName =(e)=>{
    setFullName(e.target.value)
  }
  const onChangeContactNo =(e)=>{
    setContactNo(e.target.value)
  }
  const onChangeCategory =(e)=>{
    setCategory(e.target.value)
  }
  const onChangeJobOverViewLocation =(e)=>{
    setJobOverviewLocation(e.target.value)
  }
  const onChangeOfferedSalary =(e)=>{
    setOfferedSalary(e.target.value)
  }
  const onChangePosted =(e)=>{
    setPosted(e.target.value)
  }
  const onChangeExperience =(e)=>{
    setExperience(e.target.value)
  }
  const onChangeIndustry =(e)=>{
    setIndustry(e.target.value)
  }
  const onChangeLevel =(e)=>{
    setLevel(e.target.value)
  }
  const onChangeApplied =(e)=>{
    setApplied(e.target.value)
  }
  const onChangeMessage =(e)=>{
    setMessage(e.target.value)
    if(e.target.value){
      setisshow(false)
    }
  }
  const handleChangeDepartmnt=(e)=>{
    setJobDepartment(e.target.value)
  } 
  const handleChangeEmploymentType=(e)=>{
    setEmploymentType(e.target.value)
  } 
  const handleChangeExprnce=(e)=>{
    setJobExp(e.target.value)
  } 
  const handleChangeQualification=(e)=>{
    setJobQuali(e.target.value)
  } 
  const handleChangeEducLvl=(e)=>{
    setJobEducLvl(e.target.value)
  } 
  const onChangeCheckbox=(e)=>{
    // console.log(e.target.checked)
    if(e.target.checked===true){
      // alert("checked")
      setDataPrivacy("Y")
      setCheckbox(false)
      // setApplyDsbld(false)
      if(filesResume==="" && contactNo==="" && fullName==="" && emailAdd===""){
        setButtons(true)
      }
      else {
        setButtons(false)
      }
      if(contactNo==="" && fullName==="" && emailAdd===""){
        setApplyDsbld(true)
      }
      else {
        setApplyDsbld(false)
      }
      // setOpenModalPrivcy(true)
      // openModal(openModal)
    }
    else{
      // alert("not checked")
      setDataPrivacy("N")
      setButtons(true)
      setCheckbox(true)
      setApplyDsbld(true)
    }
  } 
  const onChangeCheckboxResume=(e)=>{
    // console.log(e.target.checked)
    if(e.target.checked===true){
      // alert("checked")
      setDataPrivacy("Y")
      setisshow(false)
      setisDataprivacy(false)
    }
    else{
      setDataPrivacy("N")
      setisDataprivacy(true)
    }
  } 
  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
    ...values,
    [name]: value
    });
  };
  const onclickVerify = () =>{
    // setVerified("1")
    setModalOtp(false)
    // handleApplyGuest()
    
  }


  const handleApplySignedIn = () =>{
    let dataHris = JSON.parse(sessionStorage.getItem("dataHris"))
    console.log("dataHris")
    console.log(dataHris)

    const dateTmp = moment(date).format('LLLL').split(", ")
    const s1 = dateTmp[1]
    const s2 = dateTmp[2]
    const combDate = s1 + " " + s2
    // // console.log("combDate")
    // // console.log(combDate)
    if(message===""){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please input message.")
      setfade(true)
      return
    }
    if(dataPrivacy==="N"){
      setalerttype("Danger!")
      setisshow(true)
      setcolor("danger")
      setmessagealert("Please accept data privacy!.")
      setfade(true)
      return
    }
    const data = {
      email : dataHris===null ? emailAdd : dataHris.emailAddress,
      name : dataHris===null ? fullName : dataHris.fullName,
      contact : dataHris===null ? contactNo : dataHris.mobileNumber,
      message : message,
      jobDepartment : _jobDepartment,
      employementType : _jobEmploymentType,
      experience : _jobExprnc,
      qualification : _jobQualification,
      educLevel : _jobEducLvl,
      expectedSalary : offeredSalary,
      city : landMark,
      industry : "",
      level : "",
      isRegister : dataHris===null? "1" : "0",
      resume : filesResume,
      dataPrivacy : dataPrivacy,
      postingId : jobDetailsPostingId,
      title : title,
      companyName : companyName,
      employeeId : dataHris===null ? employeeIdTmp : dataHris.employeeId,
      status : "NW",
      createdDate : _createdDate,
      endDate : _endDate,
      jobDocNo : numberApplcants,
      dateApplied : combDate,

      createdDate : moment(date).format("MM/DD/YYYY"),
      createdBy : employeeIdTmp,
      isModified : "0",
      modifiedDate : moment(date).format("MM/DD/YYYY"),
      modifiedby : employeeIdTmp,
      isDeleted : "0",
      isPhr : dataHris===null?"0":"1",
    }
    console.log("data")
    console.log(data)
    
    const params = {
      "_collection" : "Candidates",
      "doc_data" : data
    }

    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(resp =>{
      // let data = resp.data
      if(resp.data==="success"){
        sessionStorage.setItem("successdata", JSON.stringify(data))
        navigateSuccScreen("/SuccessScreen")
      }
      else{
        alert("error")
      }
    })
    .catch(error =>{
      alert("error: " + error)
    })
  }
  const NavigateSignIn=()=>{
    // // console.log(companyId)
    // // console.log(jobPostingId)
    sessionStorage.setItem("companyId", JSON.stringify(companyId))
    sessionStorage.setItem("key", JSON.stringify(jobPostingId))
    // // console.log(jobPostingId)
    navigateSignIn("/signin");
  }
  const NavigateSignUp=()=>{
    // // console.log(companyId)
    // // console.log(jobPostingId)
    // sessionStorage.setItem("companyId", JSON.stringify(companyId))
    // sessionStorage.setItem("key", JSON.stringify(jobPostingId))
    // // console.log(jobPostingId)
    navigateSignUp("/signup");
  }
  const handleNavigateProfile = () =>{
    navigateCandidProf("/ApplicationFormCreate");
  }
  const UploadResume=(e)=>{
    // console.log(e.target.files[0])
    if(typeof e.target.files[0] === "undefined"){
      setFilePdf("")
    }
    else {
      setFilePdf(e.target.files[0].name)
    }
  }
  const handleChangeSwitch =(e)=>{
  }
  const handleOpenModal =(e)=>{
    setfrstModal(false)
    setScndModal(true)
  }
  const handleOpenModal2 =(e)=>{
    setScndModal(false)
    setThrdModal(true)
  }
  const handleOpenResume =(e)=>{
    // setScndModal(false)
    setThrdModal(false)
    setModalResume(true)
  }
  const handleDirectbyResume =()=>{
    setfrstModal(false)
    setCheckbox(true)
    setDataPrivacy("N")
    setFilePdf("")
    setModalApplyByResume(true)
  }

  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden mt-1">
        <CardBody className="p-4" style={{ marginTop : "4%"}}>
          <Row >
            <Col lg={2}>
            </Col>
            <Col lg={8}>
              <Row style={{marginLeft : "25%"}}>
                <Alert isOpen={isshow} color={color} fade={fade} className={isshow ? 'display-block' : 'display-none'} style={{maxWidth: "60%"}}>
                    <div className={color}></div> 
                  <b>{messageAlert}</b>
                </Alert>
                <div>
                <Row>
                  <Label className="mb-1" style={{ fontSize: "18px",color: "#000000", font: "inter"}}>
                    Applying for
                  </Label>
                  <Col md={8}>
                    <Label className="mt-2" style={{color:"#0A6219", fontSize: "20px"}}><b>{title}</b></Label><br />
                    <Label className="mb-1 text-muted" style={{ fontSize: "18px", marginTop: "-1%"}}>{companyName}</Label>
                  </Col>
                </Row>
                  <Row>
                    <div className="mt-3 mt-lg-0">
                      <li className="list-inline-item">
                        <p className="text-muted mb-0" style={{fontSize: "14px"}}>
                          <i className="mdi mdi-map-marker"></i>{" "}
                          {landMark}
                        </p>
                      </li>
                    </div>
                  </Row>
                  <Row>
                    <div className="mt-3 mt-lg-0">
                      <li className="list-inline-item">
                        <p className="text-muted mb-0" style={{fontSize: "14px"}}>
                          <i className="mdi mdi-clock"></i>{" "}
                            {workingDays}
                        </p>
                      </li>
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <Col lg={7}>
                      <Label className="form-label" for="inputGroupFile01" /* style={{fontWeight: "bold"}} */>
                        Message
                      </Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="nameControlInput"
                        // placeholder="Message"
                        style={{ /* borderStyle: "outset", */ backgroundColor: "#ffffff", border : "2px solid", borderColor : "green"}}
                        value={message}
                        onChange={onChangeMessage}
                        rows={8}
                        // aria-rowspan={3}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={1}>
                      <Input
                        style={{border: "1px solid green"}}
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        // to="otpscreen"
                        // disabled={checkDisable}
                        onClick={e =>onChangeCheckboxResume(e)}
                      />
                    </Col>
                    <Col lg={6}>
                      <p className="text-muted mb-0" style={{ textAlign: "justify", fontSize: "9px", marginLeft: "-11%"}}>
                        {/* By uploading your resume and apply for this job, you agreed to our 
                        <b style={{color: "#0A6219"}} to ="datapolicy" onClick={openModal}> Data Privacy Policy </b>
                        of collecting, storing and using your personal data as described in our policy 
                        to matches the candidate skill needed by the employers.  */}
                        When you apply for this job, your JobSpace profile including any verified credentials will be accessed by the employer. Other employers and recruiters
                        can also approach you with job opportunities based on your profile access settings.
                      </p>
                    </Col>
                  </Row>
                  <Col>
                    <Row className="mt-3">
                      <Col>
                        <button type="submit" className="btn btn-success" disabled={isDataprivacy} to="otpscreen" onClick={handleApplySignedIn}  style={{minWidth : "50%", textAlign: "center", marginBottom :"20%", marginLeft: "3%"}}>
                          Submit Application
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Row>
            </Col>
            <Col  lg={2}>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div
        className="modal fade"
        id="datapolicy"
        tabIndex="-1"
        aria-labelledby="datapolicy"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="modal-body p-5" style={{backgroundColor: "#F0FEED"}}>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h9 style={{color: "#00000", fontSize : "14px", fontWeight: "bolder", textAlign: "center"}}>
                  DATA PRIVACY POLICY
                </h9>
              </Row>
              <p style={{marginTop: "6%", textAlign:"justify", fontSize : "14px",}}>
                We believe you should be able to access and control your personal information no matter where you live. Depending on how you use JobSpace, you may have the
                right to request access to, correct, amend, delete, restrict, or object to certain uses of your personal information.

                <div style={{marginTop: "3%"}}></div>
                We will not charge you more or provide you with a different level of service if you exercise any of these rights, Please note that a number of these rights
                apply only in certain circumstances, and all of these rights may be limited by law.

                <div style={{marginTop: "3%"}}></div>
                We will retain your personal data only as long as necessary to fulfill the purposes for which we have collected it. To determine the appropriate retension period,
                we consider the amount, nature and sensitivity of your personal data, the potential risk of harm from unauthorized use or disclosure of your personal data,
                the purposes for which we process your personal data and whether we can achieve those purposes through other means and the applicable legal requirements.

                <div style={{marginTop: "3%"}}></div>
                We will also retain and use your personal information to extent necessary to comply with our legal obligations, resolve disputes and enforce our policies.

                <div style={{marginTop: "3%"}}></div>
                If you stop using our services or if you delete your account with us, we will delete your information or store your information in an aggregated and anonymized format.

              </p>
              <Row>
                <Col sm={3}>
                </Col>
                <Col sm={3}>
                  <label style={{fontSize : "14px", fontWeight : "bolder", marginTop: "3%"}}>
                    I Agree
                  </label>
                </Col>
                <Col sm={5}>
                  <Switch
                    checked={true}
                    onChange={handleChangeSwitch}
                    // onClick={()=>handleClickSwitch()}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Col>
                <Col sm={1}>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplyCompProf;
