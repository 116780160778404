import React, { useState, useRef, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {
    CardHeader,
    CardTitle,
    CardText,
    CardFooter,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  //Form,
  FormGroup,
  Label,
  Spinner,
  /* Alert, */
  Button,
  Card,
  CardBody,
  CardLink
} from "reactstrap";
//import "./otp.css"
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
import ScrolltoTop from "../../components/ScrolltoTop";
//import NavBar from "../../Layout/CommonLayout/NavBar"

const OTPInput = ({ length = 6 }) => {
  const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userdata1")));
  //const [otpsession, setotpsession] = useState(JSON.parse(sessionStorage.getItem("genOTP")));
  const [getOtp, setOTP]= useState("")
  //const [getOtp, setOTP]= useState<(string | number)>(['', '', '', '', '', ''])
  
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(length).fill(''));

  const [isEmailMode, setIsEmailMode] = useState(true); // added by Nel
  //const [getOtp, setOTP]= useState(Array(length).fill('1,2,3,4,5,6'));
  //const [getOtp, setOTP] = useState([1,2,3,4,5,6]);
  const inputs = useRef([]);
  const [pagePath, setPagePath] = useState(true)
  const [count, setCount] = useState(0);

  useEffect(
      () => {
        //console.log("setCount")
        //console.log((Math.round((Math.random() * (900000) + 100000))).toLocaleString().replace(/\B(?=(\d{1})+(?!\d))/g, ","))
        //setOtp((Math.round((Math.random() * (900000) + 100000))).toLocaleString().replace(/\B(?=(\d{1})+(?!\d))/g, ","))
        //localStorage.clear();
        //sessionStorage.clear();
  
          //handleData();
         // forLoop();
          //forLoop2();
         // handleValidateOtp()
         //console.log("otpsession")
         //let tmpOtp = String(otpsession).replace('"', "").replace(',', "").replace(',', "").replace(',', "").replace(',', "").replace(',', "")
         //console.log(tmpOtp)
      },
      []
  );

  // useEffect(
  //   () => {
  //     getOTPTrans();
  //   })

  // const getOTPTrans=async(e)=>{

  //   const params = {
  //     "_collection" : "UserCollection"
  //   }

  //   await axios
  //   .post("http://206.189.95.201:8098/action/get", params)
  //   .then(res => {
  //     console.log("res");
  //     console.log(res.data);
  //   })

  // }

  const handleValidateOtp = () =>{
    console.log("handleValidateOtp")
    let tmpOtp = String(otp).replace('"', "").replace(',', "").replace(',', "").replace(',', "").replace(',', "").replace(',', "")
    const params = {
      "_collection" : "otptran",
      "userid" : userdata.email,
      "userotp" : tmpOtp
    }

    console.log("params")
    console.log(params)
    axios
    .post(AppConfiguration.Setting().noserotpgen + "action/validateotp", params )
    // .post("http://139.59.239.178:9094/" + "action/genotp", params )
    .then(resp =>{
      let data = resp.data
      console.log("handleValidateOtp data:")
      console.log(data)
     if(data === 1){
      verify()
     }else{
      setErrors({otp : "OTP is incorect"})
     }
    })
    .catch(error =>{
      alert("error: " + error)
    })
    // handleApplyGuest
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
      console.log("newOtp")
      console.log(newOtp)
    }

    if (value === '' && index > 0) {
      inputs.current[index - 1].focus();
      //console.log("value")
      //console.log(value)
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (index > 0) {
        e.preventDefault();
        inputs.current[index - 1].focus();
      }
      console.log("Backspace")
      console.log(index )
      if(index === 5){
        setOTP(otp[5] = "")
        inputs.current[index].value = ""
      }
      if(index === 4){
        setOTP(otp[4] = "")
        inputs.current[index].value = ""
      }
      if(index === 3){
        setOTP(otp[3] = "")
        inputs.current[index].value = ""
      }
      if(index === 2){
        setOTP(otp[2] = "")
        inputs.current[index].value = ""
      }
      if(index === 1){
        setOTP(otp[1] = "")
        inputs.current[index].value = ""
      }
      if(index === 0){
        setOTP(otp[0] = "")
        inputs.current[index].value = ""
      }
    }
  };

  const verify =(e) =>{
    console.log("otp[0]")
    console.log(otp)
    console.log("getOtp")
    console.log(getOtp)
    let tempOtp = otp[0]+otp[1]+otp[2]+otp[3]+otp[4]+otp[5]
    // if(tempOtp === getOtp){
      //console.log("true")
      
      setErrors({otp : ""})
      const params = {
        // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
        "_collection" : "UserCollection",
        "doc_data" : userdata
      }
        //console.log("verify")
        //console.log(params)
      //sessionStorage.setItem("userdata", JSON.stringify([dataList]))
       
      axios
      .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
      .then(res => {
        //console.log("res")
        //console.log(res.data)
        sessionStorage.setItem("userCollection", JSON.stringify([userdata]))
        sessionStorage.setItem("userCollectionArr", JSON.stringify([userdata]))
        sessionStorage.setItem("userdataArray", JSON.stringify([userdata]))
        sessionStorage.setItem("userdata", JSON.stringify([userdata]))
        sessionStorage.setItem("userdata1", JSON.stringify(userdata))
        if(userdata.isEmployer === "1"){
          CompanyChecklist()
          //console.log("isEmployer === 1")
        }else{
          canditateCheckList()
          //console.log("isEmployer === 0")
        }
        //navigate("/cfverify");
        // //console.log(`welcome to Noser Technology Ph`)
        // //console.log(res)
        // sessionStorage.setItem("Client", JSON.stringify(_data))
        // sessionStorage.setItem("uname", _data[0] ['name'])
        //commenitemsDatated by Clifford 02/09/2022
        //no need to get id from Client colelction
        //id is now generated and pass as parameter to this.handleParentCompany(parentId)
        // handleGetCompany();          //code below replaces above this.handleGetCompany()
        ////////////this.handleParentCompany(clientId)
        //this.handleGetCompanyID()
        // window.location.reload(false);
      })
      .catch(error=>{
          //console.log("error: " + error)
          //console.log("ewwow")
      })
      
    // }else{
    //   //console.log("false")
    //   setErrors({otp : "OTP is incorect"})
    //   //errors.otp = 'One Time Password is incorect';
    // }
  }

  const CompanyChecklist =async(e)=>{
    let d = new Date();
    let createddate = moment(d).format('MM/DD/YYYY');
    let w = d.toLocaleTimeString()
    //User for Client ID
    let today = new Date()
    let clientId = moment(today).format('MMYYYYhhmmss');
    let obj = {
      "userId" : userdata.userId,
      "basicInfoCompany" : "0",
      "businessPermit" : "0",
      "isDeleted" : "0",
      "createdDate"             : createddate,
      "timestamp"               : w,
      "isDraft"                 : "0",
      "isModifiedDate"          : ""
    }
    const params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CompanyRegisteredCheckList",
      "doc_data" : obj
    }
      //console.log("CompanyChecklist")
      //console.log(params)
      //sessionStorage.setItem("userdata", JSON.stringify([dataList]))
     


    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(res => {
      //console.log("res")
      //console.log(res.data)
      if(res.status === 200){
        //sessionStorage.setItem("companyProfile", JSON.stringify(dataList))
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));


        navigate("/CompanyRegister");
      /*   if(pagePath){
          navigate("/CompanyRegister");
        } */
      }
      
      // //console.log(`welcome to Noser Technology Ph`)
      // //console.log(res)
      // sessionStorage.setItem("Client", JSON.stringify(_data))
      // sessionStorage.setItem("uname", _data[0] ['name'])
      //commenitemsDatated by Clifford 02/09/2022
      //no need to get id from Client colelction
      //id is now generated and pass as parameter to this.handleParentCompany(parentId)
      // handleGetCompany();          //code below replaces above this.handleGetCompany()
      ////////////this.handleParentCompany(clientId)
      //this.handleGetCompanyID()
      // window.location.reload(false);
    })
    .catch(error=>{
        //console.log("error: " + error)
        //console.log("ewwow")
    })
  }

  const canditateCheckList =async(e)=>{
    let obj = {
      "userId" : userdata.userId,
      "education" : "0",
      "basicInfoCandidate" : "0",
      "workExperience" : "0",
      "isDeleted"    :    "0",  
    }
    const params = {
      // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
      "_collection" : "CandidateRegisteredCheckList",
      "doc_data" : obj
    }
    //console.log("canditateCheckList")
    //console.log(params)
    //sessionStorage.setItem("userdata", JSON.stringify([dataList]))
     
    axios
    .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
    .then(res => {
      //console.log("res")
      //console.log(res.data)

      if(res.status === 200){
        //sessionStorage.setItem("companyProfile", JSON.stringify(dataList))
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "001"}));
        navigate("/ApplicationFormCreate");
        if(pagePath){
          navigate("/ApplicationFormCreate");
        }
      }
    })
    .catch(error=>{
        //console.log("error: " + error)
        //console.log("ewwow")
    })
  }

  const toggleMode = () => {
    setIsEmailMode(!isEmailMode);
  };

  return (
      <Row className="justify-content-center">
        <Card
            className="my-2 otpStyle"
            color="success"
            outline
            style={{
                width: '700px'
            }}
        >
            {/* Email/Mobile OTP Card */}
            <CardBody>
              <CardTitle tag="h5">
                Verify your application
              </CardTitle>
              <Row>
                <Col md={6}>
                  <FormGroup>
                      <Label for="exampleCity">
                      {isEmailMode ? 'Email Address' : 'Mobile Number'}
                      </Label>
                  </FormGroup>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                  <FormGroup>
                      <Button
                       className="mb-3 noser-button"
                       color="success"
                       onClick={toggleMode} >
                        Change
                      </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  <FormGroup>
                      <Label for="exampleCity">
                      {isEmailMode
                    ? 'We have sent an OTP to your email address'
                    : 'We have sent an OTP to your mobile number'}
                      </Label>&nbsp;
                      <CardLink href="#" className=" text-decoration-underline">
                      {isEmailMode ? userdata.email : userdata.contact}
                      </CardLink>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <FormGroup>
                      <Label for="exampleCity" className='mt-2'>
                      Enter OTP :
                      </Label>
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                    {otp.map((_, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={otp[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputs.current[index] = el)}
                            style={{
                              width: '40px',
                              height: '40px',
                              margin: '0 5px',
                              textAlign: 'center',
                              fontSize: '18px',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                            }}
                          />
                        ))}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <FormGroup>
                  </FormGroup>
                </Col>
                <Col md={8}>
                  <FormGroup>
                  {errors.otp && <span className="invalid">{errors.otp}</span>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <FormGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Button 
                      className="mb-3 noser-button-lg"
                      color="success"
                      onClick={handleValidateOtp}
                      disabled={!isEmailMode}
                    > 
                    {isEmailMode ? 'Verify Email Address' : 'Verify Mobile Number'}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label for="exampleCity">
                    Didn't receive your OTP?
                    </Label>&nbsp;
                    <CardLink href="#" className=" text-decoration-underline">
                      Resend
                    </CardLink>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <CardLink href="#" className=" text-decoration-underline">
                    Change Mobile Number
                    </CardLink>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>

            </Card>
      </Row>
  );
};

const Success = () => {
  return (
    <React.Fragment  >
   <div>
    <div style={{height : "150px"}}></div>
      <Container fluid='true'>
        <OTPInput length={6} />
      </Container>
   </div>
    <ScrolltoTop/>
    </React.Fragment>
  );
};

export default Success;