import React, { useState,useEffect } from "react";
import {
    CardHeader,
    CardTitle,
    CardText,
    CardFooter,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  //Form,
  FormGroup,
  Label,
  Spinner,
  /* Alert, */
  Button
} from "reactstrap";
import { Alert } from 'reactstrap';
import { useForm } from "react-hook-form";


import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
//import useForm from "./useForm";
import InputGroup from 'react-bootstrap/InputGroup';

import {  Card,   CardBody } from "reactstrap";

import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
//import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';



const CompanyRegisterView = ({submitForm }) => {
    const [userdata, setUserdata] = useState(JSON.parse(sessionStorage.getItem("userdataArray")));
    const navigate = useNavigate();
    const [pagePath, setPagePath] = useState(true)
    const [show, setShow] = useState(false);
    const [dissableBtn, setDissableBtn] = useState(true);
    const [isChecked, setIsChecked] = useState(false);

    const steps = ["Company Profile", "Upload Documents"];
    const [currentStep, setCurrentStep] = useState(1);
    const [complete, setComplete] = useState(false);
    const [headerId, setheaderId] = useState("")
    const [arr1, setarr] = useState([])
    const [isshow, setIsShow] = useState(false);
    const [color, setColor] = useState("")
    const [fade, setFade] = useState(true);
    const [message, setMessage]  = useState("")

    const [passState, setPassState] = useState(false);
    const [passState1, setPassState1] = useState(false);
    const [loading,setLoading] =  useState(false);
    const { errors, register, handleSubmit, control } = useForm();

    /* const { handleChange, handleSubmit, values, errors,loading,errorVal,isChecked,isEmployer,dissableBtn,handleCheckBox,handleEmployer } = useForm(
      submitForm,
      validate
    ); */

    const [values, setValues] = useState({
        companyName             : "",
        contactNumberPhone      :"",
        email                   :"",
        website                 :"",
        buildingName            :"",
        secRegNo                :"",
        city                    :"",
        birNo                   :"",
        stateProvinf            :"",
        sssRegNo                :"",
        streetName              :"",
        philHealth              :"",
        baranggay               :"",
        hdmfNo              :"",
        country                 :"",
        zipCode                 :"",
        fullnamePresident       :"",
        contactPresident        :"",
        contactPresidentPhone        :"",
        emailPresident          :"",
        identificationType      :"",
        tinNo                   :"",
        identificationNo        :"",
        subdivision             :"",
        doleRegNo            :"",
        contactPresidentPhone : "",
        doleRegNo  : "",
    });

    useEffect(
        () => {
            handleGetCompanyRegister()
        },
        []
    );

    const handleGetCompanyRegister = async() => {
        console.log(userdata)
        let filter_data = {
        "isDeleted" : "0", 
        "userId" : userdata[0].userId
        }
         
        const Params = {
        "_collection" : "CompanyProfile",
        "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        await axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {
     
            let dataTmp = []
            let dataa = res.data
            console.log(res)
            console.log("data")
            console.log(dataa)
            let newstr2=""
            let json
            dataa.CompanyProfile.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
                //console.log(json)
                dataTmp.push(json)
                //CRdata.push(json)
                //setCRdata(json)
            })    
            console.log("dataTmp")
            console.log(dataTmp)
            setValues({...values,
                companyName             : dataTmp[0].companyName,
                contactNumberPhone      : dataTmp[0].contactNumberPhone,
                email                   : dataTmp[0].email,
                website                 : dataTmp[0].website,
                buildingName            : dataTmp[0].buildingName,
                secRegNo                : dataTmp[0].secRegNo,
                city                    : dataTmp[0].city,
                birNo                   : dataTmp[0].birNo,
                stateProvinf            : dataTmp[0].stateProvinf,
                sssRegNo                : dataTmp[0].sssRegNo,
                streetName              : dataTmp[0].sssRegNo,
                philHealth              : dataTmp[0].sssRegNo,
                baranggay               : dataTmp[0].baranggay,
                hdmfNo                  : dataTmp[0].hdmfNo,
                country                 : dataTmp[0].country,
                zipCode                 : dataTmp[0].zipCode,
                fullnamePresident       : dataTmp[0].fullnamePresident,
                contactPresident        : dataTmp[0].contactPresident,
                emailPresident          : dataTmp[0].emailPresident,
                identificationType      : dataTmp[0].identificationType,
                tinNo                   : dataTmp[0].tinNo,
                identificationNo        : dataTmp[0].identificationNo,
                subdivision             : dataTmp[0].subdivision,
                contactPresidentPhone   : dataTmp[0].contactPresidentPhone,
                doleRegNo               : dataTmp[0].doleRegNo
            })
             
             /* setcompanyName(dataTmp[0].companyName)
             setcontactNumberPhone(dataTmp[0].contactNumberPhone)
             setemail(dataTmp[0].email)
             setwebsite(dataTmp[0].website)
             setbuildingName(dataTmp[0].website)
             setsecRegNo(dataTmp[0].secRegNo)
             setcity(dataTmp[0].city)
             setbirNo(dataTmp[0].birNo)
             setstateProvinf(dataTmp[0].stateProvinf)
             setsssRegNo(dataTmp[0].sssRegNo)
             setstreetName(dataTmp[0].streetName)
             setphilHealth(dataTmp[0].philHealth)
             setbaranggay(dataTmp[0].baranggay)
             sethdmfNo(dataTmp[0].hdmfNo)
             setcountry(dataTmp[0].country)
             setzipCode(dataTmp[0].zipCode)
             setfullnamePresident(dataTmp[0].fullnamePresident)
             setcontactPresident(dataTmp[0].contactPresident)
             setemailPresident(dataTmp[0].emailPresident)
             setidentificationType(dataTmp[0].identificationType)
             settinNo(dataTmp[0].tinNo)
             setidentificationNo(dataTmp[0].identificationNo) */
 
             
            //setCRdata(dataTmp)
            setLoading(false)
            
        })
        .catch(error =>{
            alert("error:  handleGetCompanyRegister" + error)
            setLoading(true)
        })
    };

    const onFormSubmit=(event)=>{
        console.log("handleSubmitProfile")
        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        let today = new Date()
        let clientId = moment(today).format('MMYYYYhhmmss');

        let dataList = {
            "employerId"            : clientId,
            "userId"                : userdata.userId,
            "companyName"           : values.companyName,
            "contactNumberPhone"    : values.contactNumberPhone,
            "email"                 : values.email, 
            "website"               : values.website,
            "buildingName"          : values.buildingName,
            "secRegNo"              : values.secRegNo,
            "city"                  : values.city,
            "birNo"                 : values.birNo,
            "stateProvinf"          : values.stateProvinf, 
            "sssRegNo"              : values.sssRegNo, 
            "streetName"            : values.streetName,
            "subdivision"           : values.subdivision,
            "philHealth"            : values.philHealth,
            "baranggay"             : values.baranggay,
            "hdmfNo"                : values.hdmfNo, 
            "country"               : values.country,
            "zipCode"               : values.zipCode,
            "fullnamePresident"     : values.fullnamePresident,
            "contactPresident"      : values.contactPresident,
            "contactPresidentPhone" : values.contactPresidentPhone,
            "emailPresident"        : values.emailPresident, 
            "identificationType"    : values.identificationType, 
            "tinNo"                 : values.tinNo, 
            "identificationNo"      : values.identificationNo, 
            "doleRegNo"      : values.doleRegNo,


            "createddate"   : createddate,
            "modifieddate"  : createddate,
            "timestamp"     : w,
            "isModified"    : "",
            "isModifiedDate"    : "",
            "isDeleted"     : "0",
            "isDraft"       : "0",
            "roleId"          : "",
        }
        const params = {
            // "dbname"      :  AppConfiguration.Setting().jobSpaceDBUAT,
            "_collection" : "CompanyProfile",
            "doc_data" : dataList
        }
         
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().mongoJobspace + "action/add", params )
        .then(res => {
            sessionStorage.setItem("CompanyProfile", JSON.stringify([dataList]))
           
        console.log("res")
        console.log(res.status)
        console.log(res.data)
        if(res.status === 200){

            handleGetCompanyChecklist(clientId)
            /* sessionStorage.setItem("companyProfile", JSON.stringify(dataList))
            window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"})); */


            //navigate("/ContinueR");
            //if(pagePath){
                //navigate("/CompanyDocuments")
            //}
        }
        //navigate("/signin");
       // sessionStorage.setItem("userdata", JSON.stringify(dataList))
        // sessionStorage.setItem("uname", _data[0] ['name'])
        })
        .catch(error=>{
            console.log("error: " + error)
            console.log("ewwow")
        }) 
    
    };

    const handleGetCompanyChecklist = async(clientId) => {
        console.log("handleGetCompanyChecklist")
        let filter_data = {
          "isDeleted" : "0", 
          "userId" : userdata.userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CompanyRegisteredCheckList",
          "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        console.log("s")
         axios
         .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            console.log("res.data")
            console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            console.log("data")
            console.log(dataaa)
            let newstr2=""
            dataaa.CompanyRegisteredCheckList.map(function(itm,i){
              let strJson = "{" + itm.substring(46, itm.length)
              let strJson2 = itm.substring(18, itm.length)
              newstr2 = strJson2.split("')")[0]
              let json = JSON.parse(strJson.replace(/'/g,'"'));
              json.headerId = newstr2
             // console.log(json.headerId)
              //setheaderId(json.headerId)
              dataTmp.push(json)
              a = json
            })    
           // setheaderId(dataTmp[0].h)
            //updateCompanyRegisteredCheckList()
            console.log("dataTmp")
            console.log(dataTmp)
            dataTmp.forEach(element => {
                element.basicInfoCompany ="1"
            });
            setheaderId(dataTmp[0].headerId)
            //let b =  
            setarr(dataTmp)
            if(res.status === 200){
                updateCompanyRegisteredCheckList(a,dataTmp,clientId)
            }
           
            //sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    const updateCompanyRegisteredCheckList=(a,dataTmp,clientId)=>{
       console.log("updateCompanyRegisteredCheckList")
        //console.log(dataTmp[0])

        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        //User for Client ID
        let today = new Date()
        //let clientId = moment(today).format('MMYYYYhhmmss');
        let obj = {
          "userId" : userdata.userId,
          "employerId"            : clientId,
          "basicInfoCompany" : "1",
          "businessPermit" :a.businessPermit,
          "isDeleted" : "0",
          "createdDate"             :a.createdDate,
          "timestamp"               : a.timestamp,
          "isDraft"                 : "0",
          "isModifiedDate"          : w
        };

        const updateParams = {
            "_collection" : "CompanyRegisteredCheckList",
            "doc_data"    : obj,
            "_id"         :a. headerId,
        };

        console.log(" toUpdate")
        console.log(updateParams)
    
        axios
        .post("http://206.189.95.201:8098/action/update_one", updateParams)
        .then(res => {
            let data = res.data
            console.log(data)
            if(res.status === 200){
                handleGetCompanyChecklist2()
            }
        
        
        }).catch(error =>{
            alert("error: " + error)
        })
    }

    const handleGetCompanyChecklist2 = async() => {
        console.log("handleGetCompanyChecklist")
        let filter_data = {
          "isDeleted" : "0", 
          "userId" : userdata.userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CompanyRegisteredCheckList",
          "filter_data" : filter_data
        };
        console.log("Params")
        console.log(Params)
        console.log("s")
         axios
         .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            console.log("res.data")
            console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            console.log("data")
            console.log(dataaa)
            let newstr2=""
            dataaa.CompanyRegisteredCheckList.map(function(itm,i){
              let strJson = "{" + itm.substring(46, itm.length)
              let strJson2 = itm.substring(18, itm.length)
              newstr2 = strJson2.split("')")[0]
              let json = JSON.parse(strJson.replace(/'/g,'"'));
              json.headerId = newstr2
              //console.log(json.headerId)
              //setheaderId(json.headerId)
              dataTmp.push(json)
              //a = json
            })    
           // setheaderId(dataTmp[0].h)
            //updateCompanyRegisteredCheckList()
            console.log("dataTmp")
            console.log(dataTmp)
            /* dataTmp.forEach(element => {
                element.basicInfoCompany ="1"
            });*/
            if(res.status === 200){
                navigate("/CompanyDocuments")
            } 
           
            //sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
        })
        .catch(error =>{
            alert("error: " + error)
        })
    };

    const handleSubmitFILES=(event)=>{
        event.preventDefault();
        console.log("submit file")
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
        ...values,
        [name]: value
        });
        
        //console.log(values)
        let a = []
        
        // a.push(values)
        // if(e.target.value.length  > 0 ){
        //     a.forEach(element => {
        //         if(element.contactNumberPhone !== "" && element.email !== "" && element.website !== "" && element.buildingName !== "" && element.secRegNo !== "" &&
        //             element.streetName !== "" && element.philHealth !== "" && element.baranggay !== "" && element.hdmfNo !== "" && element.country !== "" &&
        //             element.zipCode !== "" && element.fullnamePresident !== "" && element.contactPresident !== "" && element.emailPresident !== "" && element.identificationType !== "" &&
        //             element.tinNo !== "" && element.identificationNo !== "" 
        //         ){
        //             setDissableBtn(false)
        //         }/* else if(e.target.value.length == 0){
                    
        //             setDissableBtn(true)
        //         } */
        //     });
        // }else{
        //     setDissableBtn(true)
        // }
        
    };
    const handleCheckBox =(event)=>{
        console.log(event.target.checked)
        if(event.target.checked === true){
            setIsChecked(true)
            setDissableBtn(false)
            //setAgree("YES")
            //console.log("yes")
          }else{
            setIsChecked(false)
            setDissableBtn(true)
            //setAgree("NO")
            //console.log("no")
          }
    }

    const handleUpdate=()=>{
        navigate('/CompanyRegisterEdit')
    }

    document.title = "Sign Up | JobSpace - Job Listing Template | Themesdesign";
  return (
    <React.Fragment  >
     {/*  <TopBar /> */}
       <NavBar />
      <div > 
        <Container fluid='true' >
        <Row className="justify-content-center">
            <div style={{height : "100px"}}></div>
            <Card
                className="my-2 companyRegisterClass"
                color="success"
                outline
                style={{
                    width: '1500px'
                }}
            >
            <CardHeader tag="h5" className="companyRegisterClass">
                Create Company Profile
            </CardHeader>
            <CardBody>
                <Form action="/" className="auth-form" onSubmit={handleSubmit(onFormSubmit)}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleCity">
                                    Company Name
                                </Label>
                                <Input
                                    name="companyName"
                                    placeholder="Enter Company Name"
                                    type="text"
                                    readOnly
                                    autoComplete="off"
                                    value={values.companyName} 
                                    onChange={handleChange}
                                    
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleCity">
                                    Contact No. (Phone)*
                                </Label>
                                <Input
                                    name="contactNumberPhone"
                                    placeholder="Enter Contact No. (Phone)*"
                                    type="text"
                                    autoComplete="off"
                                    value={values.contactNumberPhone} 
                                    onChange={handleChange}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <FormGroup>
                            <Label for="exampleEmail">
                            E-mail Address
                            </Label>
                            <Input
                                id="exampleEmail"
                                name="email"
                                placeholder="Enter E-mail Address"
                                type="email"
                                autoComplete="off"
                                value={values.email} 
                                onChange={handleChange}
                                readOnly
                            />
                        </FormGroup>
                        </Col>
                        <Col md={6}>
                        <FormGroup>
                            <Label for="exampleCity">
                            Website
                            </Label>
                            <Input
                                name="website"
                                placeholder="Enter Website"
                                type="text"
                                autoComplete="off"
                                value={values.website} 
                                onChange={handleChange}
                                readOnly
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    {/* START COMPANY DETAILS */}
                    <Card
                        className="my-2 companyRegisterClass"
                    >
                        <CardHeader tag="h5" className="companyRegisterClass">
                            COMPANY ADDRESS
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Room/Floor/Unit No./Building Name
                                        </Label>
                                        <Input
                                            name="buildingName"
                                            placeholder="Enter Room/Floor/Unit No./Building Name"
                                            type="text"
                                            autoComplete="off"
                                            value={values.buildingName} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Lot./Blk No./Street Name
                                        </Label>
                                        <Input
                                            name="streetName"
                                            placeholder="Enter Lot./Blk No./Street Name"
                                            type="text"
                                            autoComplete="off"
                                            value={values.streetName} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Subdivision*
                                        </Label>
                                        <Input
                                           readOnly
                                            name="subdivision"
                                            placeholder="Enter Subdivision*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.subdivision} 
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Barangay*
                                        </Label>
                                        <Input
                                            name="baranggay"
                                            placeholder="Enter Barangay*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.baranggay} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Country*
                                        </Label>
                                        <Input
                                            name="country"
                                            placeholder="Enter Country*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.country} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Province/Region*
                                        </Label>
                                        <Input
                                            name="stateProvinf"
                                            placeholder="Enter Province/Region*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.stateProvinf} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            City/Municipality*
                                        </Label>
                                        <Input
                                            name="city"
                                            placeholder="Enter City/Municipality*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.city} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                        ZIP Code*
                                        </Label>
                                        <Input
                                            name="zipCode"
                                            placeholder="Enter ZIP Code*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.zipCode} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {/* END COMPANY DETAILS */}

                    {/* START PRESIDENTS DETAILS */}
                    <Card
                        className="my-2 mt-4 companyRegisterClass"
                    >
                        <CardHeader tag="h5" className="companyRegisterClass">
                            PRESIDENT'S DETAILS
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Full Name*
                                        </Label>
                                        <Input
                                            name="fullnamePresident"
                                            placeholder="Enter Full Name*"
                                            type="text"
                                            autoComplete="off"
                                            value={values.fullnamePresident} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleEmail">
                                        E-mail Address
                                        </Label>
                                        <Input
                                            name="emailPresident"
                                            placeholder="Enter Email Address"
                                            type="email"
                                            autoComplete="off"
                                            value={values.emailPresident} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Tax Identification No. (TIN)
                                        </Label>
                                        <Input
                                            name="tinNo"
                                            placeholder="Enter Tax Identification No. (TIN)"
                                            type="text"
                                            autoComplete="off"
                                            value={values.tinNo} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Mobile*
                                        </Label>
                                        <Input
                                            name="contactPresident"
                                            placeholder="+63-***-*******"
                                            type="text"
                                            autoComplete="off"
                                            value={values.contactPresident} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Phone No
                                        </Label>
                                        <Input
                                           

                                            name="contactPresidentPhone"
                                            placeholder="785-00-00"
                                            type="text"
                                            autoComplete="off"
                                            value={values.contactPresidentPhone} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Identification Type
                                        </Label>
                                        <Input
                                            name="identificationType"
                                            placeholder="Enter Identification Type"
                                            type="text"
                                            autoComplete="off"
                                            value={values.identificationType} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            Identification No.
                                        </Label>
                                        <Input
                                            name="identificationNo"
                                            placeholder="Enter Identification No."
                                            type="text"
                                            autoComplete="off"
                                            value={values.identificationNo} 
                                            onChange={handleChange}
                                            readOnly
                                            //onInputChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {/* END PRESIDENTS DETAILS */}

                    {/* START COMPANY REGISTRATIONS */}
                    <Card
                        className="my-2 mt-4 companyRegisterClass"
                    >
                        <CardHeader tag="h5" className="companyRegisterClass">
                        COMPANY'S REGISTRATIONS
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            SSS Registration No.*
                                        </Label>
                                        <Input
                                            name="sssRegNo"
                                            placeholder="00-0000000-0-000"
                                            type="text"
                                            autoComplete="off"
                                            value={values.sssRegNo} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                        BIR Registration No.*
                                        </Label>
                                        <Input
                                            name="birNo"
                                            placeholder="000-000-000-000-0000"
                                            type="text"
                                            autoComplete="off"
                                            value={values.birNo} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                        SEC Registration No.*
                                        </Label>
                                        <Input
                                            name="secRegNo"
                                            placeholder="AA-000000000"
                                            type="text"
                                            autoComplete="off"
                                            value={values.secRegNo} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                            HDMF No.*
                                        </Label>
                                        <Input
                                            name="hdmfNo"
                                            placeholder="0000-0000-0000"
                                            type="text"
                                            autoComplete="off"
                                            value={values.hdmfNo} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                        PHIC No.*
                                        </Label>
                                        <Input
                                            name="philHealth"
                                            placeholder="00-0000000000-00"
                                            type="text"
                                            autoComplete="off"
                                            value={values.philHealth} 
                                            onChange={handleChange}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleCity">
                                        DOLE Registration No.*
                                        </Label>
                                        <Input
                                           name="doleRegNo"
                                           placeholder="00-0000000000-00"
                                           type="text"
                                           autoComplete="off"
                                           value={values.doleRegNo} 
                                           onChange={handleChange}
                                           readOnly
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {/* END COMPANY REGISTRATIONS */}
                    <Row className="mt-3">
                        <Col md={10}></Col>
                        <Col >
                            <FormGroup>
                                <Button
                                    //type="submit"
                                    onClick={handleUpdate}
                                    color="success"
                                >
                                    {loading ? <Spinner size="lg" color="light" /> : "UPDATE"}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            </Card>
        </Row>
        </Container>
        <ScrolltoTop />
      </div>
    </React.Fragment>
  );
};



export default CompanyRegisterView;