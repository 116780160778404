//  import {
//    /*  BootstrapTable, */
//     /* paginationFactory, *//* Button, ButtonToolbar, Card, ReactDOM,
//     Form, Col, Row, axios, Container, */ /* Banner, *//*  Typeahead, TimePicker, */
//     /* cellEditFactory, */ Type, /* moment, *//*  NavLink, Alert, */ /* AppConfiguration, */
//     NoserLoading, Noser, sizePerPageRenderer,/*  DatePicker, */ /* Tabs, Tab, */ /* Accordion,Navigate */
// } 
// from '../../../noser-hris-components';

import {
    // Col,
    // Container,
    // Row,
    // Dropdown,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
    Input,
    // Modal,
    // ModalBody,
    // Form,
    // FormGroup,
    // Label,
    // Spinner,
    // Alert,
    /* Button */
  } from "reactstrap";
import React, { Component } from 'react';
//import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, ButtonToolbar, Card,  Tabs, Tab,   DropdownButton, Accordion,Form,Col,Row,/* Alert, */Container } from "react-bootstrap";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from 'axios';
import moment from 'moment';
import { AppConfiguration } from "read-appsettings-json";
import { Navigate } from "react-router-dom";
//import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Label } from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import { Alert } from 'reactstrap';
import Resizer from "react-image-file-resizer";
import '../../../wcomponents/w.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import AWS from "aws-sdk";




class ApplicationFormView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date(),
            // selected: [],
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true, 
            
            disableRemoveEducation:true,

            clientDDL       : [],
            clientId        : "",
            clientName      : "",
            positionDDL     : [],
            positionId      : "",
            positionName    : "",
            dateApplied     : "",
            titleDDL: [
                {name: 'Select Name Title', value: "",},
                {name: 'MR.',               value: 'Mr.',},
                {name: 'MS.',               value: 'Ms.',},
                {name: 'MRS.',              value: 'Mrs.',},
            ],
            suffixDDL: [
                {name: 'Select Suffix Name', value: "",},
                {name: 'SR',               value: 'SR',},
                {name: 'JR',               value: 'JR',},
                {name: 'I',              value: 'I',},
                {name: 'II',              value: 'II',},
                {name: 'III',              value: 'III',},
                {name: 'IV',              value: 'IV',},
                {name: 'V',              value: 'V',},
                {name: 'VI',              value: 'VI',},
                {name: 'VII',              value: 'VII',},
                {name: 'VIII',              value: 'VIII',},
                {name: 'IX',              value: 'IX',},
                {name: 'X',              value: 'X',},
            ],
            titleValue  : "",
            fName       : "",
            nName       : "",
            mName       : "",
            lName       : "",
            sName       : "",
            typeDDL: [
                {name: 'Select Address Type',   value: "",},
                {name: 'CURRENT ADDRESS',       value: '0',},
                {name: 'REGISTERED ADDRESS',    value: '1',},
                {name: 'PROVINCIAL ADDRESS',    value: "2",},
                {name: 'CORRESPONDENCE ADDRESS',value: '3',},
                {name: 'FOREIGN ADDRESS',       value: '4',},
            ],
            typeId      : "",
            regionDDL   : [],
            region      : "",
            regionId    : "",
            regionName  : "",
            provinceDDL : [],
            provinceId  : "",
            provinceName: "",
            cityDDL     : [],
            cityId      : "",
            cityName    : "",
            houseNumber : "",
            street      : "",
            barangay    : "",
            postalCode  : "",
            hpNumber    : "",
            opNumber    : "",
            mobileNumber: "",
            emailAddress: "",
            facebook    : "",
            twitter     : "",
            nationalityDDL: [
                {name: 'Select Citizenship',value: "",},
                {name: 'FILIPINO',          value: '1',},
                {name: 'OTHERS',            value: '2',},
            ],
            nationalityId : "",
            religionDDL: [
            ],
            religionId : "",
            genderDDL: [
                {name: 'Select Gender', value: "",},
                {name: 'MALE',          value: 'MALE',},
                {name: 'FEMALE',        value: 'FEMALE',},
            ],
            genderValue : "",
            civilStatusDDL: [
                {name: 'Select Civil Status', value: "",},
                {name:"SINGLE",         value:"SINGLE"},
                {name:"MARRIED",        value:"MARRIED"},
                {name:"DIVORCED",       value:"DIVORCED"},
                {name:"SEPARATED",      value:"SEPARATED"}
            ],
            civilStatusValue : "",
            ageYear  :   "",//,new Date().getFullYear(),
            ageMonth :   "",//,new Date().getMonth()+1,
            dateOfBirth : "",
            fromStart : "",
            toEnd: "",
            totalAge : "",
            placeOfBirth : "",
            height : "",
            weight : "",
            sSkills : "",
            lSpoken : "",
            ecName : "",
            relationship : "",
            ecNumber : "",
            sss : "",
            tin : "",
            phic : "",
            pagibig : "",


            levelDDL  :    [
                {"name": 'Select Level', "value": "",},
                {"name":"COLLEGE","value":"0"},
                {"name":"HIGH SCHOOL","value":"1"},
                {"name":"SENIOR HIGH SCHOOL","value":"5"},
                {"name":"JUNIOR HIGH SCHOOL","value":"4"},
                {"name":"ELEMENTARY","value":"2"},
                {"name":"OTHERS","value":"3"},
            ],
            levelDDLtwo  :    [
                {"id":"1","name":"COLLEGE","LevelId":"0", "isModified":"0","isDeleted":"0"},
                {"id":"2","name":"HIGH SCHOOL","LevelId":"1", "isModified":"0","isDeleted":"0"},
                {"id":"3","name":"SENIOR HIGH SCHOOL","LevelId":"5", "isModified":"0","isDeleted":"0"},
                {"id":"4","name":"JUNIOR HIGH SCHOOL","LevelId":"4", "isModified":"0","isDeleted":"0"},
                {"id":"5","name":"ELEMENTARY","LevelId":"2", "isModified":"0","isDeleted":"0"},
                //{"id":"6","name":"OTHERS","LevelId":"3", "isModified":"0","isDeleted":"0"},
            ],
            LevelId : "",
            tblLevelArrLst  :   [],
            SchoolName:"",
            Course:"",
            StartYear:"",
            EndYear:"",
            honorsAward:"",
            educationGrid : [],

            checkIsFreshGraduate : false,
            isEmploymentDisabled : false,
            erCompanyName : "",
            erCompanyAddress : "",
            erPosition : "",
            erPeriodCover : "",
            erSalary : "",
            erSupervisor : "",
            erCNumber : "",
            erRFLeaving : "",
            erTCIncome : "",
            erWHTax : "",
            erYOCompensation : "",
            erMPAOB : "",
            erDBenefits : "",
            erMDeduction : "",
            erNTSOFOC: "",
            erBasicSalary : "",
            erTMPOB : "",
            erTSPFOC : "",
            erBPFMWE : "",
            erHPFHWE : "",
            erOPFHWE : "",
            erNDPFHWE : "",
            erRemarks : "",
            employmentRecordGrid : [],
            disableRemoveEmploymentRecord : true,

            roleDDL  :    [
                {"name": 'Select Role', "value": "",},
                {"name":"FATHER",       "value":"0"},
                {"name":"MOTHER",       "value":"1"},
                {"name":"SPOUSE",       "value":"2"},
                {"name":"BROTHER",      "value":"3"},
                {"name":"SISTER",       "value":"4"},
                {"name":"CHILD",        "value":"5"},
            ],
            roleDDLtwo  :    [
                
                {"id":"1","name":"FATHER",       "RoleId":"0"},
                {"id":"2","name":"MOTHER",       "RoleId":"1"},
                {"id":"3","name":"SPOUSE",       "RoleId":"2"},
                {"id":"4","name":"BROTHER",      "RoleId":"3"},
                {"id":"5","name":"SISTER",       "RoleId":"4"},
                {"id":"6","name":"CHILD",        "RoleId":"5"},
            ],
            tblRoleArrLst : [],
            RoleId : "",
            fbName : "",
            fbAge : "",
            fbOccupation : "",
            fbCOSchool : "",
            fbCNumber : "",
            disableBackground : true,
            familyBackgroundGrid : [],

            tblJobOpeningArrLst : [],
            jobOpeningDDL  :    [
                // {"name": 'Select Job Opening',  "value": "",},
                // {"name":"BESTJOBS",             "value":"1"},
                // {"name":"CLASSIFIED ADS",       "value":"2"},
                // {"name":"FIELD COLLECTORS",     "value":"3"},
                // {"name":"JOBSTREET",            "value":"4"},
                // {"name":"OTHERS",               "value":"5"},
                // {"name":"PESO",                 "value":"6"},
                // {"name":"REFERRAL",             "value":"7"},
            ],
            jobSourceId : "",
            jobSource : "",
            oReferredBy : "",
            checkORelativeYes : false,
            checkORelativeNo : true,
            disabledRelative : false,
            oRelativeName : "",
            oCNumber : "",
            oRelationship : "",
            oJobTitle : "",
            disableOthers : true,

            checkConvictedYes : false,
            checkConvictedNo : true,
            oCrime : "",
            disabledConvicted : false,

            checkLaborUnionYes : false,
            checkLaborUnionNo : true,
            disabledLaborUnion : true,
            luDetails : "",
            checkChronicDiseaseYes : false,
            checkChronicDiseaseNo : true,
            disabledDisease : true,

            tblDiseaseArrLst : [],
            diseaseDDL  :    [
                {"name": 'Select Chronic Disease',  "value": "",},
                {"name":"ALLERGIES",            "value":"1"},
                {"name":"ASTHMA",               "value":"2"},
                {"name":"DIABETES",             "value":"3"},
                {"name":"HEPA B",               "value":"4"},
                {"name":"HYPERTENSION",         "value":"5"},
                {"name":"N/A",                  "value":"6"},
                {"name":"OTHERS",               "value":"7"}
            ],
            diagnoseDeseaseId : "",
            othersGrid : [],

            referralDDL:[],
            referralLst:[],
            disablereferral: true,
            referralId:"",
            referenceName : "",
            referenceOccupation : "",
            referenceCOSchool : "",
            referenceCNumber : "",
            referenceGrid : [],
            disableReference : true,

            checkDisclaimer : false,
            disablebutton : true,

            arrangementId       :   "",
            arrangement         :   "",
            bloodtypeId         :   "",
            bloodtype           :   "",

            arrangementDDL      :   [],
            bloodtypeDDL        :   [],

            firstNamekeycode    :   "",
            middleNamekeycode   :   "",
            lastNamekeycode     :   "",
            nickNamekeycode     :   "",
            newImage: "",
            file : null,
            s3Objects: [],
            newImageShow: false,
            photoLabel : true,
            uploadResume : "",



            //image

            file : null,
            s3Objects: [],

            //w value af
            clientIdAH : "",
            clientNameAH: "",
            positionIdAH: "",
            positionNameAH:"",
            refNoAF : "",
            dateAppliedAF: "",
            remarksAF : "",
            applicationHistoryGrid: [],
            typeofcharacterreference:[
                {"name": 'Select Role', "value": ""},
                {"name":"Previous Employment", "value" : "0"},
                {"name":"Neighbor", "value" : "1"}
            ],
            tbltypeofcharacterreferenceArrLst : [],
            typeofcharacterreferenceId : "",
            typeofcharacterreferenceName : "",
            wstartDate : "",
            wendDate: "",
            //others
            referealOthersTA: true,
            referealOthersTXT : false,
            fileImage : "",
            candiProfile: [],


            titleValue : "",
            headerId : "",
            UserId : "",
            CandidateProfileId  : "",
        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.fileUploadChangedHandler = this.fileUploadChangedHandler.bind(this);
    }


    componentDidMount() {
        // const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userCollection"))
        // this.GetClient(); 
        // this.GetPosition();
         this.GetRegion();
        this.GetLevel();
        this.GetRole();
         this.Gettypeofcharacterreference();
         this.GetDisease();
         this.GetJobOpening();
        // if(this.state.checkORelativeNo === true) {
        //     this.setState({
        //         disabledRelative : true,
        //         oRelativeName : "",
        //         oCNumber : "",
        //         oRelationship : "",
        //         oJobTitle : "",
        //     })
        // }
        // if(this.state.checkConvictedNo === true) {
        //     this.setState({
        //         disabledConvicted : true,
        //         oCrime : "",
        //     })
        // }
        // if(this.state.checkLaborUnionNo === true) {
        //     this.setState({
        //         disabledLaborUnion : true,
        //         luDetails : "",
        //     })
        // }
        // if(this.state.checkChronicDiseaseNo === true) {
        //     this.setState({
        //         disabledDisease : true,
        //         diagnoseDeseaseId : "",
        //     })
        // }
        this.getCandiProfile()
         //this.autofillEducation()
         this.GetLivingArrangements()
         //this.fetchS3Objects();
         this.getprofileImage()

        /* this.state.referenceGrid = [
            {
                "id": moment(new Date()).format('HHMMSS') + 1,
                "typeId" : "",
                "typeofCharacterReferenceName" : "",
                "name"          :   "",
                "contactNumber" :   "",
                "occupation"    :   "",
                "company"       :   "",
                "userId"        :   this.state.userinfo.userId,
                "isDeleted"    :    "0",  
            },
            {
                "id": moment(new Date()).format('HHMMSS') + 2,
                "typeId" : "",
                "typeofCharacterReferenceName" : "",
                "name"          :   "",
                "contactNumber" :   "",
                "occupation"    :   "",
                "company"       :   "",
                "userId"        :   this.state.userinfo.userId,
                "isDeleted"    :    "0",  
            },
            {
                "id": moment(new Date()).format('HHMMSS') + 3,
                "typeId" : "",
                "typeofCharacterReferenceName" : "",
                "name"          :   "",
                "contactNumber" :   "",
                "occupation"    :   "",
                "company"       :   "",
                "userId"        :   this.state.userinfo.userId,
                "isDeleted"    :    "0",  
            }
        ] */
    };

    getprofileImage=(data)=>{
        console.log("getprofileImage 111111111111111")
        let filter_data = {
            "isDeleted" : "0", 
            "userId" : this.state.userinfo[0].userId,
            //"isDeleted" : "0",
            /*  "firstName" : firstName,
            "lastName"  : lastName, */
        }
        
        const Params = {
            "_collection" : "imageChecklist",
            "filter_data" : filter_data
        }
        console.log("Params")
        console.log(Params)
        //console.log("s")
            axios
            .post(AppConfiguration.Setting().mongoJobspace +"action/get", Params)
        .then(res => {
    
            //console.log("res.data")
            //console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            //console.log("data ")
            //console.log(dataaa)
            let newstr2=""
            dataaa.imageChecklist.map(function(itm,i){
                let strJson = "{" + itm.substring(46, itm.length)
                let strJson2 = itm.substring(18, itm.length)
                newstr2 = strJson2.split("')")[0]
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                json.headerId = newstr2
               
                dataTmp.push(json)
               
            })    
            
            console.log("imageChecklist")
            console.log(dataTmp)
           
            // let h2 = data.filter((item0) => item0.key === "9");
            // data.map(function(itm,i){
            //     if(itm.Key === dataTmp[0].filename){
            //         console.log(itm)
            //     }
            // })    
            //this.setState({candiProfile : dataTmp })
            this.fetchS3Objects(dataTmp)
        })
        .catch(error =>{
            alert("error: imagecheck list " + error +" getprofileImage11")
        })
    };


    fetchS3Objects = (dataTmp) => {

        console.log("dataTmp fetchS3Objects")
        console.log(dataTmp)

        // Configure AWS SDK
        AWS.config.update({
            accessKeyId: 'AKIAUQ62QJHPG6KLKGPX',
            secretAccessKey: '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', 
            region: 'us-east-1',
            correctClockSkew: true
        });

        const s3 = new AWS.S3();
        const params = {
            Bucket: 'nelbucket-456',
        };

        s3.listObjectsV2(params, (err, data) => {
            if (err) {
                console.log("Error fetching objects from S3: ", err);
            } else {
                console.log("Fetched objects: ", data.Contents);
                let a = []
                data.Contents.map(function(itm,i){
                    if(itm.Key === dataTmp[0].filename){
                        console.log(itm)
                        a.push(itm)
                    }
                })  
                console.log("aaaaaasduahskdhaksjdhakjsdhakjsdh")
                console.log(a)
                console.log(a.length)
                this.setState({ s3Objects: data.Contents });
                if (a.length > 0) {
                    console.log()
                    
                    this.viewS3Object(a[0].Key);
                }
                //this.getprofileImage(data.Contents)
            }
        });
    }
    viewS3Object = (objectKey) => {
        console.log("objectKey")
        console.log(objectKey)

        const s3 = new AWS.S3();
        const params = {
            Bucket: 'nelbucket-456',
            Key: objectKey,
        };
        console.log("params")
        console.log(params)
    
        const fileType = objectKey.split('.').pop().toLowerCase();
    
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
            s3.getSignedUrl('getObject', params, (err, url) => {
                if (err) {
                    console.error("Error generating pre-signed URL: ", err);
                } else {
                    console.log("url")
                    console.log(url)
                    this.setState({
                        newImage: url,
                        newImageShow: true,
                        photoLabel: false,
                    }); 
                }
            });
        } else {
            console.error("Unsupported file type for rendering:", fileType);
        }
    };
    // viewS3Object = (objectKey) => {
    //     const s3 = new AWS.S3();
    //     const params = {
    //         Bucket: 'nelbucket-456',
    //         Key: objectKey,
    //     };

    //     const fileType = objectKey.split('.').pop().toLowerCase();
    //     //console.log("fileType: ", fileType);

    //     if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') {
    //         s3.getSignedUrl('getObject', params, (err, url) => {

    //             if (err) {
    //                 console.error("Error generating pre-signed URL: ", err);
    //             } else {
    //                 this.setState({ selectedFileContent: <img src={url} style={{ width: '100%', height: '500px', border: 'none' }} alt="Image Document" /> });
    //             }
    //         })
    //     } else {
    //         s3.getObject(params, (err, data) => {
    //             if (err) {
    //                 console.error("Error getting object from S3: ", err);
    //             } else {
    //                 console.log("Object fetched successfully: ", data);
        
    //                 const pdfBlob = new Blob([data.Body], { type: 'application/pdf' });
    //                 const pdfUrl = URL.createObjectURL(pdfBlob);
                  
    //                 this.setState({ selectedFileContent: <iframe src={pdfUrl} style={{ width: '100%', height: '500px', border: 'none' }} title="PDF Document"></iframe> });
    //             }
    //         });
    //     }

    // }


   

    // getprofileImage=(data)=>{
    //     console.log("getprofileImage 111111111111111")
    //     let filter_data = {
    //         "isDeleted" : "0", 
    //         "userId" : this.state.userinfo[0].userId,
    //         //"isDeleted" : "0",
    //         /*  "firstName" : firstName,
    //         "lastName"  : lastName, */
    //     }
        
    //     const Params = {
    //         "_collection" : "imageChecklist",
    //         "filter_data" : filter_data
    //     }
    //     console.log("Params")
    //     console.log(Params)
    //     //console.log("s")
    //         axios
    //         .post(AppConfiguration.Setting().mongoJobspace +"action/get", Params)
    //     .then(res => {
    
    //         //console.log("res.data")
    //         //console.log(res)
    
    //         let dataTmp = []
    //         let a
    //         let dataaa = res.data
    //         //console.log("data ")
    //         //console.log(dataaa)
    //         let newstr2=""
    //         dataaa.imageChecklist.map(function(itm,i){
    //             let strJson = "{" + itm.substring(46, itm.length)
    //             let strJson2 = itm.substring(18, itm.length)
    //             newstr2 = strJson2.split("')")[0]
    //             let json = JSON.parse(strJson.replace(/'/g,'"'));
    //             json.headerId = newstr2
               
    //             dataTmp.push(json)
               
    //         })    
            
    //         console.log("imageChecklist")
    //         console.log(dataTmp)
           
    //         // let h2 = data.filter((item0) => item0.key === "9");
    //         // data.map(function(itm,i){
    //         //     if(itm.Key === dataTmp[0].filename){
    //         //         console.log(itm)
    //         //     }
    //         // })    
    //         //this.setState({candiProfile : dataTmp })
    //         this.fetchS3Objects(dataTmp)
    //     })
    //     .catch(error =>{
    //         alert("error: imagecheck list " + error +" getprofileImage11")
    //     })
    // };

    // fetchS3Objects = (dataTmp) => {
    //     console.log("fetchS3Objects")
    //     console.log(dataTmp)
    //     AWS.config.update({
    //         accessKeyId: 'AKIAUQ62QJHPG6KLKGPX',
    //         secretAccessKey: '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',
    //         region: 'us-east-1',
    //         correctClockSkew: true,
    //     });
    
    //     const s3 = new AWS.S3();
    //     const params = {
    //         Bucket: 'nelbucket-456',
    //         //  Key : dataTmp[0].filename,
    //     };

    //     console.log("params fetchS3Objects")
    //     console.log(params)
    
    //     s3.listObjectsV2(params, (err, data) => {
    //         if (err) {
    //             console.error("Error fetching objects from S3: ", err);
    //         } else {
    //             console.log("Fetched objects: ", data.Contents);
    //             this.setState({ s3Objects: data.Contents });
    
    //             data.Contents.map(function(itm,i){
    //                 console.log(itm)
    //              })    

    //             if (data.Contents.length > 0) {
    //                 console.log()
                    
    //                 this.viewS3Object(data.Contents[0].Key);
    //             }
    //         }
    //     });
    // };
    
    // viewS3Object = (objectKey) => {
    //     console.log("objectKey")
    //     console.log(objectKey)

    //     const s3 = new AWS.S3();
    //     const params = {
    //         Bucket: 'nelbucket-456',
    //         Key: objectKey,
    //     };
    //     console.log("params")
    //     console.log(params)
    
    //     const fileType = objectKey.split('.').pop().toLowerCase();
    
    //     if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
    //         s3.getSignedUrl('getObject', params, (err, url) => {
    //             if (err) {
    //                 console.error("Error generating pre-signed URL: ", err);
    //             } else {
    //                 console.log("url")
    //                 console.log(url)
    //                 this.setState({
    //                     newImage: url,
    //                     newImageShow: true,
    //                     photoLabel: false,
    //                 }); 
    //             }
    //         });
    //     } else {
    //         console.error("Unsupported file type for rendering:", fileType);
    //     }
    // };
    

    ValidNumeric(val){
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    };
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    };
    IsDecimal(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    };

    getCandiProfile=()=>{
   
        //console.log("getCandiProfile")
        let filter_data = {
          "isDeleted" : "0", 
          "UserId" : this.state.userinfo[0].userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CandidatesProfile",
          "filter_data" : filter_data
        }
        //console.log("Params")
        //console.log(Params)
        //console.log("s")
         axios
         .post(AppConfiguration.Setting().mongoJobspace +"action/get", Params)
        .then(res => {
  
            //console.log("res.data")
            //console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            //console.log("data")
            //console.log(dataaa)
            let newstr2=""
            dataaa.CandidatesProfile.map(function(itm,i){
              let strJson = "{" + itm.substring(46, itm.length)
              let strJson2 = itm.substring(18, itm.length)
              newstr2 = strJson2.split("')")[0]
              let json = JSON.parse(strJson.replace(/'/g,'"'));
              json.headerId = newstr2
              //console.log(json.headerId)
              //setheaderId(json.headerId)
              dataTmp.push(json)
              //console.log(json)
              a = json
              //console.log(a.AgeYear)
            })    
           
            //console.log("dataTmp")
            //console.log(a.AgeYear)
            //this.setState({candiProfile : dataTmp })
            var today = new Date();
            var birthDate = new Date(a.DateOfBirth);
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
            {
                age_now--;
            }
            this.setState({
                
                fName               : a.FirstName,
                nName               : a.NickName,
                mName               : a.MiddleName,
                lName               : a.LastName,
                sName               : a.SuffixName,
                titleValue          : a.Title,
                hpNumber            : a.HomePhoneNumber,
                totalAge            : age_now,
                ageMonth            : new Date(a.DateOfBirth).getMonth()+1,
                ageYear             : birthDate.getFullYear(),
                dateApplied         : a.DateApplied,//new Date(a.DateApplied),
                dateOfBirth         : new Date(a.DateOfBirth),
                opNumber            : a.OfficeNumber,
                mobileNumber        : a.MobileNumber,
                emailAddress        : a.EmailAddress,
                facebook            : a.Facebook,
                twitter             : a.Twitter,
                nationalityId       : a.NationalityId,
                religionId          : a.ReligionId,
                genderValue         : a.Gender,
                civilStatusValue    : a.CivilStatus,                
                placeOfBirth        : a.PlaceOfBirth,
                height              : a.Height,
                weight              : a.Weight,
                sSkills             : a.SpecialSkills,
                lSpoken             : a.LanguageSpoken,
                ecName              : a.EmergencyContactName,
                relationship        : a.EmergencyContactRelation,
                ecNumber            : a.EmergencyContactNumber,
                
                sss                 : a.SSSNumber,
                tin                 : a.TINNumber,
                phic                : a.PHICNumber,
                pagibig             : a.HDMFNumber,

                arrangementId       : a.LivingArrangementId,
                arrangement         : a.Arrangement,
                bloodtypeId         : a.BloodTypeId,
                bloodtype           : a.BloodType,
                //arrangementId       : a.LivingArrangementId,
                //typeId              : a.applicantAddresses.TypeId,
                educationGrid : a.educationAttainments,
                employmentRecordGrid : a.employmentHistories,
                familyBackgroundGrid : a.familyBackgrounds,
                othersGrid : a.others,
                referenceGrid :a.characterReferences,
                headerId : a.headerId,
                UserId : a.UserId,
                CandidateProfileId : a.CandidateProfileId,
            })
            //console.log(a.educationAttainments)
            
            if(a.applicantAddresses.length>0){
                this.state.addressApplicationFormId= a.applicantAddresses.length>0 ? a.applicantAddresses[0]["applicationFormId"] : ""
                this.state.addressId=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["id"] : ""
                this.state.typeId=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["TypeId"] : ""
                this.state.regionId=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["RegionId"]: ""
                this.state.region=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["Region"] : ""
                this.state.provinceId=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["ProvinceId"] : ""
                this.state.provinceName=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["Province"] : ""
                this.state.cityId=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["CityId"] : ""
                this.state.cityName=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["City"] : ""
                this.state.houseNumber=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["HouseNumber"] : ""
                this.state.street=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["StreetName"] : ""
                this.state.barangay=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["Barangay"] : ""
                this.state.postalCode=a.applicantAddresses.length>0 ? a.applicantAddresses[0]["PostalCode"] : ""
            }
            let tmpeducationAttainments = a.educationAttainments
            tmpeducationAttainments.forEach((itm,idx) => {
                itm.id= parseFloat(idx+1)
            })
            let tmp2educationAttainments = tmpeducationAttainments
            for(let x = 0; x < tmp2educationAttainments.length; x++){
                this.state.educationApplicationFormId      = tmp2educationAttainments[x].applicationFormId
                this.state.educationId      = tmp2educationAttainments[x].id
               
            };
            // START employmentHistories APPLICATIONID
            let TmpemploymentHistories = a.employmentHistories
            TmpemploymentHistories.forEach((itm,idx) => {
                itm.id= parseFloat(idx+1).toString()
            })
            let Tmp2employmentHistories = TmpemploymentHistories
            for(let x = 0; x < Tmp2employmentHistories.length; x++){
                this.state.employmentApplicationFormId      = a.employmentHistories[x].applicationFormId
                this.state.employmentId      = a.employmentHistories[x].id
            };
            // START familyBackgrounds APPLICATIONID
            let TmpfamilyBackgrounds = a.familyBackgrounds
            TmpfamilyBackgrounds.forEach((itm,idx) => {
                itm.id= parseFloat(idx+1).toString()
            })
            let Tmp2familyBackgrounds = TmpfamilyBackgrounds
            for(let x = 0; x < Tmp2familyBackgrounds.length; x++){
                this.state.familyApplicationFormId      = Tmp2familyBackgrounds[x].applicationFormId
                this.state.familyId      = Tmp2familyBackgrounds[x].id
            }
           
            a.others.forEach(itm=>{
                this.state.othersApplicationFormId = itm.applicationFormId
                this.state.othersId = itm.id
                this.state.jobSourceId = itm.jobSourceId
                this.state.oReferredBy = itm.refferedBy

                this.state.disablereferral = false
                this.state.referealOthersTA = itm.jobSourceId ==="10" ? true : false
                this.state.referealOthersTXT = itm.jobSourceId ==="10" ? false : true


                this.state.checkORelativeYes = itm.hasCompanyRelative ==="1" ? true : false
                this.state.checkORelativeNo = itm.hasCompanyRelative ==="1" ? false : true
                this.state.disabledRelative = itm.hasCompanyRelative ==="1" ? false : true
                this.state.oRelativeName = itm.relativeName
                this.state.oRelationship = itm.relativeRelation
                this.state.oJobTitle = itm.relativePosition
                this.state.oCNumber = itm.relativeContactNumber
                

                this.state.checkConvictedYes = itm.isCrimeConvicted ==="1" ? true : false
                this.state.checkConvictedNo = itm.isCrimeConvicted ==="1" ? false : true
                this.state.disabledConvicted = itm.isCrimeConvicted ==="1" ? false : true
                this.state.oCrime = itm.crimeDescription
                
                this.state.checkLaborUnionYes = itm.isLaborUnion ==="1" ? true : false
                this.state.checkLaborUnionNo = itm.isLaborUnion ==="1" ? false : true
                this.state.disabledLaborUnion = itm.isLaborUnion ==="1" ? false : true
                this.state.luDetails = itm.laborUnionDescription

                this.state.checkChronicDiseaseYes = itm.diagnoseDeseaseId !=="" ? true : false
                this.state.checkChronicDiseaseNo = itm.diagnoseDeseaseId !=="" ? false : true
                this.state.disabledDisease = itm.diagnoseDeseaseId !=="" ? false : true
                this.state.diagnoseDeseaseId = itm.diagnoseDeseaseId
            })

            // START characterReferences APPLICATIONID
            let tmpcharacterReferences = a.characterReferences
            tmpcharacterReferences.forEach((itm,idx) => {
                itm.id= parseFloat(idx+1)
            })
            let tmp2characterReferences = tmpcharacterReferences
            for(let x = 0; x < tmp2characterReferences.length; x++){
                this.setState({
                    characterApplicationFormId : tmp2characterReferences[x].applicationFormId,
                    characterId : tmp2characterReferences[x].id,
                })
                //this.state.characterApplicationFormId      = data.characterReferences[x].applicationFormId
                //this.state.characterId      = data.characterReferences[x].id
            }
        })
        .catch(error =>{
            alert("error: asdasd get candidate " + error)
        })
    };

    GetLivingArrangements = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "0",
            "UserId"        :  "0"
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  params)
        .then(res => {
            const data = res.data;
            this.setState({arrangementDDL:data.livingArrangements});
        })
        this.GetBloodTypes()
    }
    GetBloodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
           "ClientId"      :   "0",
            "UserId"        :  "0"
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({bloodtypeDDL:data.bloodTypes});
        })
        this.GetReferralList()
    }
    GetReligions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "1",
            "UserId"        :   "1"
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetReligions",  params)
        .then(res => {
            const data = res.data;
            ////console.log("religion")
            ////console.log(data)
            let religionDDL = []
            religionDDL.push({"value":"","name":"Select Religion"})
            data.religions.map(function(itm){
                religionDDL.push({"value":itm.id,"name":itm.name})
            })
            this.setState({religionDDL:religionDDL});
        })
       
    };

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedArrangement = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.arrangementId= ''
            this.state.arrangement=''
            return
        }
        this.state.arrangementId=e[0].id
        this.state.arrangement=e[0].name
    }
    handleChangedBloodType = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.bloodtypeId= ''
            this.state.bloodtype=''
            return
        }
        this.state.bloodtypeId=e[0].id
        this.state.bloodtype=e[0].name
    };

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.positionName   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.positionName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeDateApplied = date => {
        this.setState({
            dateApplied: date,
            isshow:false,
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    FormatDate2(date) {
        let m = moment(date, 'MM-YYYY');
        return (m.isValid() ? (date.getMonth()+1  + "/" + date.getFullYear()) : "")
    }

    
    handleChangeTitle = (e) => {
        this.setState({ titleValue: e.target.value,isshow:false, });
    };

    onChangeFirstName = (e) => {
        this.setState({ fName: e.target.value,isshow:false, });
    }

    onChangeNickName = (e) => {
        this.setState({ nName: e.target.value,isshow:false });
    }

    onChangeMiddleName = (e) => {
        this.setState({ mName: e.target.value,isshow:false });
    }

    onChangeLastName = (e) => {
        this.setState({ lName: e.target.value,isshow:false });
    }
    onChangeSuffix = (e) => {
        this.setState({ sName: e.target.value,isshow:false });
    }
    handleChangeType = (e) => {
        this.setState({ typeId: e.target.value,isshow:false });
    };

    GetRegion = async()=> {
        ////console.log(" GET REGION")
        this.setState({isloading:true});
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : "0",
            "UserId"    : "0",
            "Name"      : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            const data = res.data
            ////console.log(data)
            this.setState({
                regionDDL   :   data.regions,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    handleChangeRegion = (e) => {
        if(e.length == 0) {
            this.state.regionId     =   ""
            this.state.regionName   =   ""
            return
        }
        this.state.regionId     =   e[0].id
        this.state.regionName   =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetProvince();
    }

    GetProvince = async()=> {
        this.setState({isloading:true});
        const provinceParams = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : "0",
            "UserId"    : "0",
            "Region"    : this.state.regionName
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {
            const data = res.data;
            this.setState({
                provinceDDL : data.provinces,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    };
    
    GetReferralList = async() =>{
        this.setState({isloading: true})
        
        const params = {
            "IpAddress":"0.0.0.0",
           "ClientId"      :   "",
            "UserId"        :  "0"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            ////console.log("referral")
            ////console.log(data)
            this.setState({referralLst:data.profiles.filter(x=>x.status==="ACTIVE"),isloading:false});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handledOnSearch = (e) =>{
        ////console.log("handledOnSearch")
        ////console.log(e)
        if(e===""){
            this.setState({referralDDL:[]})
        }
        else{
            this.setState({referralDDL:this.state.referralLst.filter(x => x.employeeName.toLowerCase().startsWith(e))})
            
        }
    }
    handleChangeReferral = (e) => {
        if(e.length == 0) {
            this.state.referralId     =   ""
            this.state.oReferredBy   =   ""
            return
        }
        this.state.referralId     =   e[0].id
        this.state.oReferredBy   =   e[0].employeeName
        this.setState({isshow:false,
        })
    }
    handleChangeProvince = (e) => {
        if(e.length == 0) {
            this.state.provinceId     =   ""
            this.state.provinceName   =   ""
            return
        }
        this.state.provinceId     =   e[0].id
        this.state.provinceName   =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetCity();
    }

    GetCity = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : "0",
            "UserId"    : "0",
            "RegionId"  : this.state.regionId,
            "ProvinceId": this.state.provinceId,
            "Name"      : ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                cityDDL   : data.cities,
                isloading : false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }

    handleChangeCity = (e) => {
        if(e.length == 0) {
            this.state.cityId     =   ""
            this.state.cityName   =   ""
            return
        }
        this.state.cityId     =   e[0].id
        this.state.cityName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    onChangeHouseNumber = (e) => {
        // const re = /^[0-9\b]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ houseNumber: e.target.value,isshow:false, })
        // }
        this.setState({ houseNumber: e.target.value,isshow:false, });
    };

    onChangeStreet = (e) => {
        this.setState({ street: e.target.value,isshow:false, });
    };

    onChangeBarangay = (e) => {
        this.setState({ barangay: e.target.value,isshow:false, });
    };

    onChangePostalCode = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ postalCode: e.target.value,isshow:false, })
        }
        //this.setState({ postalCode: e.target.value,isshow:false, });
    };

    onChangeHPNumber = (e) => {
        //this.setState({ hpNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ hpNumber: e.target.value,isshow:false, })
        }
    };

    onChangeOPNumber = (e) => {
        //this.setState({ opNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ opNumber: e.target.value,isshow:false, })
        }
    };

    onChangeMobileNumber = (e) => {
        //this.setState({ mobileNumber: e.target.value,isshow:false, });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ mobileNumber: e.target.value,isshow:false, })
        }
    };

    onChangeEmailAddress = (e) => {
        this.setState({ emailAddress: e.target.value,isshow:false, });
    };

    onChangeFacebook = (e) => {
        this.setState({ facebook: e.target.value,isshow:false, });
    };

    onChangeFacebook = (e) => {
        this.setState({ facebook: e.target.value,isshow:false, });
    };

    onChangeTwitter = (e) => {
        this.setState({ twitter: e.target.value,isshow:false, });
    };

    handleChangeCitizenship = (e) => {
        this.setState({ nationalityId: e.target.value,isshow:false, });
    };

    handleChangeReligion = (e) => {
        this.setState({ religionId: e.target.value,isshow:false, });
    };

    handleChangeGender = (e) => {
        this.setState({ genderValue: e.target.value,isshow:false });
    };

    handleChangeCivilStatus = (e) => {
        this.setState({ civilStatusValue: e.target.value,isshow:false });
    };

    calculate_age = (date) => {
        var today = new Date();
        var birthDate = new Date(date); 
        //////console.log("get bod-->",birthDate) // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        //////console.log('my age', age_now);
        this.setState({
            totalAge : age_now
        });
        return age_now;
    }

    handleChangeDateOfBirth = date => {
        
        this.setState({
            dateOfBirth: date,
            isshow:false,
        });
        var today = new Date();
        var birthDate = new Date(date);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age_now--;
        }
        this.state.totalAge = age_now
        this.state.ageMonth = date.getMonth()+1
        this.state.ageYear = birthDate.getFullYear()    
        /* if(date > null) {
            this.state.ageMonth = date.getMonth()+1
            this.state.ageYear = date.getFullYear()
        } */

    };

    handleChangeFromStart = date => {
        this.setState({
            fromStart: date,
            //toEnd :newEndDate,
            isshow:false,
        });
    };
    handleChangeToEnd = date => {
        this.setState({
            //fromStart: date,
            toEnd :date,
            isshow:false,
        });
    };

    onChangeAgeMonth(e){
        this.setState({totalAge:e.target.value});
    }

    onChangePlaceOfBirth = (e) => {
        this.setState({ placeOfBirth: e.target.value,isshow:false });
    };

    onChangeHeight = (e) => {
        this.setState({ height: e.target.value,isshow:false });
    };

    onChangeWeight = (e) => {
        this.setState({ weight: e.target.value,isshow:false });
    };

    onChangeSkills = (e) => {
        this.setState({ sSkills: e.target.value,isshow:false });
    };

    onChangeLSpoken = (e) => {
        this.setState({ lSpoken: e.target.value,isshow:false });
    };

    onChangeECName = (e) => {
        this.setState({ ecName: e.target.value,isshow:false });
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ ecName: e.target.value,isshow:false, })
        } */
    };

    onChangeRelationship = (e) => {
        this.setState({ relationship: e.target.value,isshow:false });
    };

    onChangeECNumber = (e) => {
        //this.setState({ ecNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ ecNumber: e.target.value,isshow:false, })
        }
          
    };

    onChangeSSS = (e) => {
        this.setState({sss: e.target.value,isshow:false})
    }

    onChangeTin = (e) => {
        this.setState({tin: e.target.value,isshow:false})
    }

    onChangePhilHealth = (e) => {
        this.setState({phic: e.target.value,isshow:false})
    }

    onChangePagibig = (e) => {
        this.setState({pagibig: e.target.value,isshow:false})
    }

    handleChangeLevel = (e) => {
        this.setState({ LevelId: e.target.value,isshow:false });
        ////console.log(e.target.value)
    };

    onChangeSchoolName = (e) => {
        this.setState({ SchoolName: e.target.value,isshow:false });
    };

    onChangeCourse = (e) => {
        this.setState({ Course: e.target.value,isshow:false });
    };

    onChangeHonorsAward = (e) => {
        this.setState({ honorsAward: e.target.value,isshow:false });
    };

    onChangeStartYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ StartYear: e.target.value,isshow:false, })
        }
        //this.setState({ StartYear: e.target.value,isshow:false });
    };

    onChangeEndYear = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ EndYear: e.target.value,isshow:false, })
        }
        //this.setState({ EndYear: e.target.value,isshow:false });
    };

    handleAddEducation = (e) =>{
        let educationGrid = this.state.educationGrid
       

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "LevelId"       :   this.state.LevelId,
            "SchoolName"    :   this.state.SchoolName,
            "Course"        :   this.state.Course,
            "StartYear"     :   this.state.StartYear,
            "EndYear"       :   this.state.EndYear,
            "HonorRecieved" :   this.state.honorsAward,
            "contactPerson" :   "",
            "contactNumber" :   "",
            "isModified"    :   "0", 
            "isDeleted"    :   "0",   

            //"level":this.state.level,
        }
        /* let a =[]
        a.push(obj)

        this.state.levelDDL.forEach(element => {
            ////console.log("element")
            ////console.log(element)
            a.forEach(elements => {
                ////console.log("elements")
                ////console.log(elements)
            });
        }); */

        educationGrid.push(obj)  
        ////console.log("educationGrid") 
        ////console.log(educationGrid)    
        this.setState({
            isshow:false,
            educationGrid:educationGrid,
            LevelId:"",
            SchoolName:"",
            Course:"",
            StartYear:"",
            EndYear:"",
            honorsAward:"",
        })

        //////console.log(educationGrid)
    }
    handleRemoveEducation = (e) =>{
        let educationGrid = this.state.educationGrid.filter(x=>x.isDeleted=="0")
        this.state.educationGrid = []
        this.setState({educationGrid:educationGrid,disableRemoveEducation: educationGrid.length===0 ? true : false})
    }

    EducationGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.educationGrid.map(function(item,i) {
           
            if (item.id===id){
               
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        //console.log(this.state.educationGrid)
        /* this.state.educationGrid.forEach(element => {
           //console.log(id)
            if (element.id===id){
                ////console.log(element.id)

               
                //console.log("asdasdasd")
                element.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
                element.SchoolName = newValue!=oldValue ? newValue : oldValue
            }
        }); */
    }

    GetLevel() {
        for (let i = 0; i < this.state.levelDDL.length; i++) {
            const obj = {
                value : this.state.levelDDL[i]["value"],
                label : this.state.levelDDL[i]["name"],
            };
            this.state.tblLevelArrLst.push(obj);
        }
    }

    onChangeERCompanyName = (e) => {
        this.setState({ erCompanyName: e.target.value,isshow:false });
    };

    onChangeERCompanyAddress = (e) => {
        this.setState({ erCompanyAddress: e.target.value,isshow:false });
    };

    onChangeERPosition = (e) => {
        this.setState({ erPosition: e.target.value,isshow:false });
    };

    onChangeERCPeriodCover = (e) => {
        this.setState({ erPeriodCover: e.target.value,isshow:false });
    };

    onChangeERSalary = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ erSalary: e.target.value,isshow:false, })
        }
        //this.setState({ erSalary: e.target.value,isshow:false });
    };

    onChangeERSupervisor = (e) => {
        this.setState({ erSupervisor: e.target.value,isshow:false });
    };

    onChangeERCNumber = (e) => {
        //this.setState({ erCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ erCNumber: e.target.value,isshow:false, })
        }
    };

    onChangeERRFLeaving = (e) => {
        this.setState({ erRFLeaving: e.target.value,isshow:false });
    };

    onChangeERCIncome = (e) => {
        this.setState({ erTCIncome: e.target.value,isshow:false });
    };

    onChangeERWHTax = (e) => {
        this.setState({ erWHTax: e.target.value,isshow:false });
    };

    onChangeERYOCompensation = (e) => {
        this.setState({ erYOCompensation: e.target.value,isshow:false });
    };

    onChangeERMPAOB = (e) => {
        this.setState({ erMPAOB: e.target.value,isshow:false });
    };

    onChangeERDBenefits = (e) => {
        this.setState({ erDBenefits: e.target.value,isshow:false });
    };

    onChangeERMDeduction = (e) => {
        this.setState({ erMDeduction: e.target.value,isshow:false });
    };

    onChangeERNTSOFOC = (e) => {
        this.setState({ erNTSOFOC: e.target.value,isshow:false });
    };

    onChangeERBasicSalary = (e) => {
        this.setState({ erBasicSalary: e.target.value,isshow:false });
    };

    onChangeERTMPOB = (e) => {
        this.setState({ erTMPOB: e.target.value,isshow:false });
    };

    onChangeERTSPFOC = (e) => {
        this.setState({ erTSPFOC: e.target.value,isshow:false });
    };

    onChangeERBPFMWE = (e) => {
        this.setState({ erBPFMWE: e.target.value,isshow:false });
    };

    onChangeERHPFHWE = (e) => {
        this.setState({ erHPFHWE: e.target.value,isshow:false });
    };

    onChangeEROPFHWE = (e) => {
        this.setState({ erOPFHWE: e.target.value,isshow:false });
    };

    onChangeERNDPFHWE = (e) => {
        this.setState({ erNDPFHWE: e.target.value,isshow:false });
    };

    onChangeERRemarks = (e) => {
        this.setState({ erRemarks: e.target.value,isshow:false, });
    };
    onChangesFreshGraduate  = (e) => {
        let employmentRecordGrid = this.state.employmentRecordGrid
        this.state.checkIsFreshGraduate = e.target.checked
        if(this.state.checkIsFreshGraduate == true) {
            
            let today = new Date()
            let newId = moment(today).format('HHMMSS');
            const obj = {
                "id":newId.toString(),
                "companyName"               :   "N/A",
                "companyAddress"            :   "N/A",
                "position"                  :   "N/A",
                "salary"                    :   "0",
                "periodCovered"             :   "N/A",
                "isModified"                :   "0",
                "supervisor"                :   this.state.erSupervisor,
                "contactNumber"             :   this.state.erCNumber,
                "reasonForLeaving"          :   "N/A",
                "taxableCompensationIncome" :   this.state.erTCIncome,
                "withholdingTax"            :   this.state.erWHTax,
                "yearOfCompensation"        :   this.state.erYOCompensation,
                "nTThirteenMonthPay"        :   this.state.erMPAOB,
                "deminimis"                 :   this.state.erDBenefits,
                "nTMandatoryDeduction"      :   this.state.erMDeduction,
                "nTSalaries"                :   this.state.erNTSOFOC,
                "basicSalary"               :   this.state.erBasicSalary,
                "taxableThirteenMonthPay"   :   this.state.erTMPOB,
                "taxableSalaries"           :   this.state.erTSPFOC,
                "basicPayMWE"               :   this.state.erBPFMWE,
                "holidayPayMWE"             :   this.state.erHPFHWE,
                "overtimePayMWE"            :   this.state.erOPFHWE,
                "nightDiffPayMWE"           :   this.state.erNDPFHWE,
                "remarks"                   :   this.state.erRemarks,
                "isDeleted"    :   "0",
            }
            employmentRecordGrid.push(obj)      
            this.setState({
                employmentRecordGrid:employmentRecordGrid,
                isshow:false,
                isEmploymentDisabled : true,
            })
            //////console.log(employmentRecordGrid)
        } else {     
            this.setState({
                employmentRecordGrid: [],
                isshow:false,
                isEmploymentDisabled : false,
            })
        }
    }

    handleAddEmploymentRecord = (e) =>{
        let employmentRecordGrid = this.state.employmentRecordGrid


        if(this.state.erCompanyName===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter company name.",
                fade        :   false
            });
            return
        }

        if(this.state.erCompanyAddress===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter company address.",
                fade        :   false
            });
            return
        }

        if(this.state.erPosition===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter position.",
                fade        :   false
            });
            return
        }

        if(this.state.erSalary===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please salary.",
                fade        :   false
            });
            return
        }

        if(this.state.fromStart===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select period covered date from.",
                fade        :   false
            });
            return
        }
        if(this.state.toEnd===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select period covered date to.",
                fade        :   false
            });
            return
        }
        /* if(this.state.erPeriodCover===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please period covered.",
                fade        :   false
            });
            return
        } */
        ////console.log(this.state.fromStart)
        ////console.log(this.state.toEnd)
        ////console.log(this.state.fromStart  + this.state.toEnd)

        let a = this.FormatDate2(this.state.fromStart)
        let b = this.FormatDate2(this.state.toEnd)
        let c = a + " " + b
        ////console.log(a + " "+ b)
        ////console.log(c)
        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "CompanyName"               :   this.state.erCompanyName,
            "CompanyAddress"            :   this.state.erCompanyAddress,
            "Position"                  :   this.state.erPosition,
            "Salary"                    :   this.state.erSalary,
            "Supervisor"                :   this.state.erSupervisor,
            "ContactNumber"             :   this.state.erCNumber,
            "ReasonForLeaving"          :   this.state.erRFLeaving,
            "FromStart"                 : a,
            "ToEnd"                     : b,
            "PeriodCovered"             : c,
            //"periodCovered"             :   this.state.erPeriodCover,
            "TaxableCompensationIncome" :   this.state.erTCIncome,
            "WithholdingTax"            :   this.state.erWHTax,
            "YearOfCompensation"        :   this.state.erYOCompensation,
            "NTThirteenMonthPay"        :   this.state.erMPAOB,
            "Deminimis"                 :   this.state.erDBenefits,
            "NTMandatoryDeduction"      :   this.state.erMDeduction,
            "NTSalaries"                :   this.state.erNTSOFOC,
            "BasicSalary"               :   this.state.erBasicSalary,
            "TaxableThirteenMonthPay"   :   this.state.erTMPOB,
            "TaxableSalaries"           :   this.state.erTSPFOC,
            "BasicPayMWE"               :   this.state.erBPFMWE,
            "HolidayPayMWE"             :   this.state.erHPFHWE,
            "OvertimePayMWE"            :   this.state.erOPFHWE,
            "NightDiffPayMWE"           :   this.state.erNDPFHWE,
            "Remarks"                   :   this.state.erRemarks,
            "isModified"                :   "0",
            "isDeleted"    :   "0",  
        }
        //console.log(obj)
        //console.log(employmentRecordGrid)  
        employmentRecordGrid.push(obj) 
        //console.log(employmentRecordGrid)     
        this.setState({
            employmentRecordGrid:employmentRecordGrid,
            isshow:false,
            
            erCompanyName : "",
            erCompanyAddress : "",
            erPosition : "",
            erPeriodCover : "",
            erSalary : "",
            erSupervisor : "",
            erCNumber : "",
            erRFLeaving : "",
            erTCIncome : "",
            erWHTax : "",
            erYOCompensation : "",
            erMPAOB : "",
            erDBenefits : "",
            erMDeduction : "",
            erNTSOFOC: "",
            erBasicSalary : "",
            erTMPOB : "",
            erTSPFOC : "",
            erBPFMWE : "",
            erHPFHWE : "",
            erOPFHWE : "",
            erNDPFHWE : "",
            erRemarks : "",
            fromStart : "",
            toEnd : "",
        })
        //////console.log(employmentRecordGrid)
    }
    handleRemoveEmploymentRecord = (e) =>{
        let employmentRecordGrid = this.state.employmentRecordGrid.filter(x=>x.isDeleted=="0")
        this.state.employmentRecordGrid = []
        this.setState({employmentRecordGrid:employmentRecordGrid,disableRemoveEmploymentRecord: employmentRecordGrid.length===0 ? true : false})
    }

    EmploymentGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.employmentRecordGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }

    applicationHistoryGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.applicationHistoryGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }



    handleChangeRole = (e) => {
        this.setState({ RoleId: e.target.value,role: e.target.name,isshow:false });
    };
    handleChangetypeofcharacterreference=(e)=>{
        ////console.log(e.target.value)
        ////console.log( e.target.name)
        this.setState({ typeofcharacterreferenceId: e.target.value,isshow:false });
    }

    onChangeFBName = (e) => {
        this.setState({ fbName: e.target.value,isshow:false });
    };

    onChangeFBAge = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({fbAge: e.target.value,isshow:false})
        }
    };

    onChangeFBOccupation = (e) => {
        this.setState({ fbOccupation: e.target.value,isshow:false });
    };

    onChangeFBCOSchool = (e) => {
        this.setState({ fbCOSchool: e.target.value,isshow:false });
    };

    onChangeFBCNumber = (e) => {
        //this.setState({ fbCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ fbCNumber: e.target.value,isshow:false, })
        }
    };

    GetRole() {
        for (let i = 0; i < this.state.roleDDL.length; i++) {
            const obj = {
                value : this.state.roleDDL[i]["value"],
                label : this.state.roleDDL[i]["name"],
            };
            this.state.tblRoleArrLst.push(obj);
        }
        
    }
    Gettypeofcharacterreference() {
        for (let i = 0; i < this.state.typeofcharacterreference.length; i++) {
            const obj = {
                value : this.state.typeofcharacterreference[i]["value"],
                label : this.state.typeofcharacterreference[i]["name"],
            };
            this.state.tbltypeofcharacterreferenceArrLst.push(obj);
        }
        
    }

    handleAddFamilybackground = (e) =>{
        let familyBackgroundGrid = this.state.familyBackgroundGrid


        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "RoleId"        :   this.state.RoleId,
            "Name"          :   this.state.fbName,
            "ContactNumber" :   this.state.fbCNumber,
            "Age"           :   this.state.fbAge,
            "Occupation"    :   this.state.fbOccupation,
            "Company"       :   this.state.fbCOSchool,
            "UserId"        :   this.state.userinfo.userId,
            "isModified"    :   "0",
            "isDeleted"     :   "0",  
            "role"          :   this.state.role,
        }
        familyBackgroundGrid.push(obj)      
        this.setState({familyBackgroundGrid:familyBackgroundGrid,isshow:false})
    }
    handleRemoveBackground = (e) =>{
        this.state.familyBackgroundGrid.forEach((itm,idx) => {
            if(idx<=5){
                itm.isDeleted="0"
            }
            
        })
        let familyBackgroundGrid = this.state.familyBackgroundGrid.filter(x=>x.isDeleted=="0")
        this.setState({familyBackgroundGrid:familyBackgroundGrid,disableBackground: familyBackgroundGrid.length===0 ? true : false})
    }

    FamilyBackgroundGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.familyBackgroundGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }



    handleChangeJobOpening = (e) => {
        
        if(e.target.value === "9" ||  e.target.value === "7"){
            this.refs.refReferredBy.clear()
            this.setState({
                jobSourceId: e.target.value,
                disablereferral:false,
                referealOthersTA : false,
                referealOthersTXT : true,
                isshow:false,
            })
        }else if(e.target.value === "10"){
            this.refs.refReferredBy.clear()
            this.setState({
                jobSourceId: e.target.value,
                disablereferral:false,
                referealOthersTA : true,
                referealOthersTXT : false,
                isshow:false,
            })
        }else if(e.target.value!=="7" ||  e.target.value === "9" || e.target.value === "10"){
            this.refs.refReferredBy.clear()
            this.setState({
                jobSourceId: e.target.value,
                isshow:false,
                disablereferral:true,
                referealOthersTA : true,
                referealOthersTXT : false,
                isshow:false,
            })
        }
    }

    onChangeOthersRefferedBy = (e) => {
        //console.log(e.target.value)
        this.setState({ oReferredBy: e.target.value,isshow:false });
    };
    

    
    onChangeORelativeYes  = (e) => {
        this.state.checkORelativeYes = e.target.checked
        if(this.state.checkORelativeYes == true) {
            this.setState({
                checkORelativeNo    :   false,
                disabledRelative    : false,
            })
        }
    }
    
    onChangeORelativeNo  = (e) => {
        this.state.checkORelativeNo = e.target.checked
        if(this.state.checkORelativeNo == true) {
            this.setState({
                checkORelativeYes   :   false,
                disabledRelative    : true,
                oRelativeName : "",
                oCNumber : "",
                oRelationship : "",
                oJobTitle : "",
            })
        }
    }

    onChangeORelativeName = (e) => {
        this.setState({ oRelativeName: e.target.value,isshow:false });
    };

    onChangeOCNumber = (e) => {
        //this.setState({ oCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ oCNumber: e.target.value,isshow:false, })
        }
    };

    onChangeORelationship = (e) => {
        this.setState({ oRelationship: e.target.value,isshow:false });
    };

    onChangeOJobTitle = (e) => {
        this.setState({ oJobTitle: e.target.value,isshow:false });
    };
    
    onChangeOthersConvictedYes  = (e) =>{
        this.state.checkConvictedYes = e.target.checked
        if(this.state.checkConvictedYes == true) {
            this.setState({
                checkConvictedNo  :   false,
                disabledConvicted  		  :   false,
            })
        }
    }
    
    onChangeOthersConvictedNo  = (e) =>{
        this.state.checkConvictedNo = e.target.checked
        if(this.state.checkConvictedNo == true) {
            this.setState({
                disabledConvicted  :   true,
                checkConvictedYes  		  :   false,
                crime : "",
            })
        }
    }
    
    onChangeOthersLaborUnionYes  = (e) =>{
        this.state.checkLaborUnionYes = e.target.checked
        if(this.state.checkLaborUnionYes == true) {
            this.setState({
                checkLaborUnionNo  :   false,
                disabledLaborUnion  :   false,
            })
        }
    }

    onChangeOCrime = (e) => {
        this.setState({ oCrime: e.target.value,isshow:false });
    };
    
    onChangeOthersLaborUnionNo  = (e) =>{
        this.state.checkLaborUnionNo = e.target.checked
        if(this.state.checkLaborUnionNo == true) {
            this.setState({
                checkLaborUnionYes  :   false,
                disabledLaborUnion  :   true,
            })
        }
    }

    onChangeLUDetails = (e) => {
        this.setState({ luDetails: e.target.value,isshow:false });
    };
    
    onChangeOthersChronicDiseaseYes  = (e) =>{
        this.state.checkChronicDiseaseYes = e.target.checked
        if(this.state.checkChronicDiseaseYes == true) {
            this.setState({
                checkChronicDiseaseNo  :   false,
                disabledDisease  :   false,
            })
        }
    }
    
    onChangeOthersChronicDiseaseNo  = (e) =>{
        this.state.checkChronicDiseaseNo = e.target.checked
        if(this.state.checkChronicDiseaseNo == true) {
            this.setState({
                checkChronicDiseaseYes  :   false,
                disabledDisease :   true,
                diagnoseDeseaseId : "",
            })
        }
    }

    handleChangeDisease = (e) => {
        this.setState({ diagnoseDeseaseId: e.target.value,chronicDisease: e.target.name,isshow:false });
    };

    GetJobOpening = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetJobOpenings",  params)
        .then(res => {
            const data = res.data;
            let jobOpeningDDL = []
            let tblJobOpeningArrLst = []
            jobOpeningDDL.push({"value":"","name":"Select Job Opening"})
            data.jobOpenings.map(function(itm){
                jobOpeningDDL.push({"value":itm.id,"name":itm.name})
                tblJobOpeningArrLst.push({"value" : itm.id,"label" : itm.name})
            })
            this.setState({jobOpeningDDL:jobOpeningDDL,tblJobOpeningArrLst:tblJobOpeningArrLst});
        })
        this.GetReligions();
    }

    GetDisease() {
        for (let i = 0; i < this.state.diseaseDDL.length; i++) {
            const obj = {
                value : this.state.diseaseDDL[i]["value"],
                label : this.state.diseaseDDL[i]["name"],
            };
            this.state.tblDiseaseArrLst.push(obj);
        }
        
    }
    
    handleAddOthers = (e) =>{
        if(this.state.checkORelativeYes === true) {
            if(this.state.oRelativeName === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter name",
                    fade:true
                });
                return
            }
            if(this.state.oCNumber === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter contact number",
                    fade:true
                });
                return
            }
            if(this.state.oRelationship === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter relationship",
                    fade:true
                });
                return
            }
            if(this.state.oJobTitle === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter job title",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkConvictedYes === true) {
            if(this.state.oCrime === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkLaborUnionYes === true) {
            if(this.state.luDetails === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please enter details",
                    fade:true
                });
                return
            }
        }
        
        if(this.state.checkChronicDiseaseYes === true) {
            if(this.state.diagnoseDeseaseId === "") {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: "please select details",
                    fade:true
                });
                return
            }
        }

        let othersGrid = this.state.othersGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "applicationFormId":"1",
            "JobSourceId":this.state.jobSourceId,
            "RefferedBy":this.state.oReferredBy,
            "HasCompanyRelative":(this.state.checkORelativeYes)? "1" : "0",
            "RelativeName":this.state.oRelativeName,
            "RelativeRelation":this.state.oRelationship,
            "RelativeContactNumber":this.state.oCNumber,
            "RelativePosition":this.state.oJobTitle,
            "isCrimeConvicted":(this.state.checkConvictedYes)? "1" : "0",
            "IsCrimeConvicted":this.state.oCrime,
            "IsLaborUnion":(this.state.checkLaborUnionYes)? "1" : "0",
            "LaborUnionDescription":this.state.luDetails,
            "DiagnoseDeseaseId":this.state.diagnoseDeseaseId,
            "UserId":this.state.userinfo.userId,
            "isModified"    :   "0",
            "isDeleted"    :   "0",  
            "jobopening"       :   this.state.jobSource,
            "diagnoseDesease":this.state.chronicDisease
        }
        othersGrid.push(obj)      
        this.setState({
            othersGrid:othersGrid,
            isshow:false,
            jobSourceId : "",
            checkConvictedYes : false,
            checkConvictedNo : true,
            oCrime : "",
            disabledConvicted : true,
            oReferredBy : "",
            checkLaborUnionYes : false,
            checkLaborUnionNo : true,
            disabledLaborUnion : true,
            luDetails : "",
            checkChronicDiseaseYes : false,
            checkChronicDiseaseNo : true,
            disabledDisease : true,
            diagnoseDeseaseId : "",
        })
        //////console.log(othersGrid)
    }
    handleRemoveOthers = (e) =>{
        let othersGrid = this.state.othersGrid.filter(x=>x.isDeleted=="0")
        this.state.othersGrid = []
        this.setState({othersGrid:othersGrid,disableOthers: othersGrid.length===0 ? true : false})
    }

    OtherGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.othersGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }


    onChangeReferenceName = (e) => {
        this.setState({ referenceName: e.target.value,isshow:false });
    };

    onChangeReferencOccupation = (e) => {
        this.setState({ referenceOccupation: e.target.value,isshow:false });
    };

    onChangeReferenceCOSchool = (e) => {
        this.setState({ referenceCOSchool: e.target.value,isshow:false });
    };

    onChangeReferenceCNumber = (e) => {
        //this.setState({ referenceCNumber: e.target.value,isshow:false });
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ referenceCNumber: e.target.value,isshow:false, })
        }
         
    };

    handleAddReference = (e) =>{
        if(this.state.typeofcharacterreferenceId === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter type of character reference",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceName === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter name",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceOccupation === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter occupation",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceCOSchool === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter company or school",
		        fade:true
		    });
		    return
        }
		if(this.state.referenceCNumber === "") {
		    this.setState({
		        isloading:false,
		        alerttype:"Error!",
		        isshow:true,
		        color:"danger",
		        message: "please enter contact number",
		        fade:true
		    });
		    return
        }
        
        let referenceGrid = this.state.referenceGrid

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "typeId" : this.state.typeofcharacterreferenceId,
            "typeofCharacterReferenceName" : this.state.typeofcharacterreferenceName,
            "name"          :  this.state.referenceName,
            "contactNumber" :   this.state.referenceCNumber,
            "occupation"    :   this.state.referenceOccupation,
            "company"       :   this.state.referenceCOSchool,
            "userId"        :this.state.userinfo.userId,
            "isDeleted"    :   "0",  
        }
        ////console.log("obj")
        ////console.log(obj)
        referenceGrid.push(obj)    
        ////console.log("referenceGrid")  
        ////console.log(referenceGrid)  
        this.setState({
            referenceGrid:referenceGrid,
            isshow:false,
            typeofcharacterreferenceId :"",
            referenceName : "",
            referenceOccupation : "",
            referenceCOSchool : "",
            referenceCNumber : "",
        })
        ////console.log(referenceGrid)
    }
    handleRemoveReference = (e) =>{
        let referenceGrid = this.state.referenceGrid.filter(x=>x.isDeleted=="0")
        this.state.referenceGrid = []
        this.setState({referenceGrid:referenceGrid,disableReference: referenceGrid.length===0 ? true : false})
    }

    ReferenceGridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.referenceGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
       // this.setState({disableRemoveEducation:disable})
    }
    
    onChangeDisclaimer  = (e) =>{
        this.state.checkDisclaimer = e.target.checked
        if(this.state.checkDisclaimer == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }
    
    handleClickStatus= async(statusId) => {
        this.setState({navigate : true})
    }

    

    checklistcandi=async(clientId)=>{
        //console.log("checklistcandi")
        let filter_data = {
            "isDeleted" : "0", 
            "userId" : this.state.userinfo.userId,
            //"isDeleted" : "0",
           /*  "firstName" : firstName,
            "lastName"  : lastName, */
        };

        const Params = {
            "_collection" : "CandidateRegisteredCheckList",
            "filter_data" : filter_data
        }

        await axios
        .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {
            //console.log("get data CandidateRegisteredCheckList ")
            //console.log("res.data")
            //console.log(res)
     
            let dataTmp = []
            let a
            let dataaa = res.data
            //console.log("data")
            //console.log(dataaa)
            let newstr2=""
            dataaa.CandidateRegisteredCheckList.map(function(itm,i){
              let strJson = "{" + itm.substring(46, itm.length)
              let strJson2 = itm.substring(18, itm.length)
              newstr2 = strJson2.split("')")[0]
              let json = JSON.parse(strJson.replace(/'/g,'"'));
              json.headerId = newstr2
              //console.log(json.headerId)
              //setheaderId(json.headerId)
              dataTmp.push(json)
              a = json
            })    
           // setheaderId(dataTmp[0].h)
            //updateCompanyRegisteredCheckList()
            //console.log("CandidateRegisteredCheckList gewt dataTmp")
            //console.log(dataTmp)
            dataTmp.forEach(element => {
                element.basicInfoCandidate ="1"
            });
            
            if(res.status === 200){
                this.updateCandidateChecklist(a,dataTmp,clientId)
            }
        })
        .catch(error =>{
            //console.log("2")
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
            return
        })
    }

    updateCandidateChecklist=(a,dataTmp,clientId)=>{
        //console.log("updateCandidateChecklist")
      
        ////console.log(dataTmp[0])

        let d = new Date();
        let createddate = moment(d).format('MM/DD/YYYY');
        let w = d.toLocaleTimeString()
        //User for Client ID
        let today = new Date()
        //let clientId = moment(today).format('MMYYYYhhmmss');
        let obj = {
            "userId"                : this.state.userinfo.userId,
            "employerId"            : clientId,
            "education"             : a.education, 
            "basicInfoCandidate"    : "1",
            "workExperience"        : a.workExperience,
            "isDeleted"             : "0",
            "createdDate"             :a.createdDate,
            "timestamp"               : a.timestamp,
            "isDraft"                 : "0",
            "isModifiedDate"          : w
        }

        const updateParams = {

            "_collection" : "CandidateRegisteredCheckList",
            "doc_data"    : obj,
            "_id"         :a.headerId,
  
        }

        //console.log(" toUpdate")
        //console.log(updateParams)
  
        axios
        .post("http://206.189.95.201:8098/action/update_one", updateParams)
        .then(res => {
            let data = res.data
            //console.log(data)
            //console.log(res.status)
            if(res.status === 200){
                this.handleGetCompanyChecklist2()
            }
       
       
        }).catch(error =>{
            //console.log("3")
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
            return
        })
    }

    handleGetCompanyChecklist2 = async() => {
        //console.log("CandidateRegisteredCheckList")
        let filter_data = {
          "isDeleted" : "0", 
          "userId" : this.state.userinfo.userId,
          //"isDeleted" : "0",
         /*  "firstName" : firstName,
          "lastName"  : lastName, */
        }
        
        const Params = {
          "_collection" : "CandidateRegisteredCheckList",
          "filter_data" : filter_data
        }
       
         axios
         .post("http://206.189.95.201:8098/action/get", Params)
        .then(res => {

            //console.log("res.data get number 2")
            //console.log(res)
    
            let dataTmp = []
            let a
            let dataaa = res.data
            //console.log("data")
            //console.log(dataaa)
            let newstr2=""
            dataaa.CandidateRegisteredCheckList.map(function(itm,i){
              let strJson = "{" + itm.substring(46, itm.length)
              let strJson2 = itm.substring(18, itm.length)
              newstr2 = strJson2.split("')")[0]
              let json = JSON.parse(strJson.replace(/'/g,'"'));
              json.headerId = newstr2
              //console.log(json.headerId)
              //setheaderId(json.headerId)
              dataTmp.push(json)
              //a = json
            })    
           // setheaderId(dataTmp[0].h)
            //updateCompanyRegisteredCheckList()
            //console.log("dataTmp")
            //console.log(dataTmp)
            /* dataTmp.forEach(element => {
                element.basicInfoCompany ="1"
            });*/
            if(res.status === 200){
                //naviga("/")
                this.setState({navigate : true})
            } 
           
            //sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
        })
        .catch(error =>{
            //console.log("4")
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
            return
        })
    };

    

    handleNickNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.nickNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.nickNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.nickNamekeycode = e.which
    }
    handleFirstNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.firstNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.firstNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.firstNamekeycode = e.which
    }
    handleMiddleNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.middleNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.middleNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.middleNamekeycode = e.which
    }
    handleLastNameDoubleSpaces = (e) =>{
        var theEvent = e || window.event;
        //var key = theEvent.keyCode || theEvent.which;
        if(this.state.lastNamekeycode === "" && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }

        if(this.state.lastNamekeycode === 32 && e.which === 32){
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
        this.state.lastNamekeycode = e.which
    };

    fileChangedHandler(event) {
        
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          try {
            this.setState({fileImage: event.target.files[0]});
            Resizer.imageFileResizer(
              event.target.files[0],
              1,
              200,
              "JPEG",
              100,
              0,
              (uri) => {
                ////console.log(uri);
                this.setState({ newImage: uri, newImageShow:true, photoLabel:false });
              },
              "base64",
              200,
              200
            );
          } catch (err) {
            ////console.log(err);
          }
        }
    };

    //w function application history
    onChangewsd = date => {
        ////console.log("newStartDate")
        ////console.log(date)
       this.setState({wstartdate : date})
        // thi
        // setStartDate(newStartDate);
        // setEndDate(newEndDate);
    };
    wenddate = date => {
        ////console.log("newendDate")
        ////console.log(date)
        this.setState({wstartdate : date})
       
        // thi
        // setStartDate(newStartDate);
        // setEndDate(newEndDate);
    };
    handleChangeClientAH=(e)=>{
        if(e.length == 0) {
            this.state.clientIdAH     =   ""
            this.state.clientIdAH   =   ""
            return
        }
        this.state.clientIdAH     =   e[0].id
        this.state.clientNameAH   =   e[0].name
    };
    handleChangePositionAH = (e) => {
        if(e.length == 0) {
            this.state.positionIdAH     =   ""
            this.state.positionNameAH   =   ""
            return
        }
        this.state.positionIdAH     =   e[0].id
        this.state.positionNameAH   =   e[0].name
    };
    onChangeRefNoAF = (e) => {
        this.setState({ refNoAF: e.target.value,isshow:false });
    };
    handleChangeDateAppliedAF = date => {
        this.setState({dateAppliedAF: date,isshow:false,})
    };
    onChangeRemarks = (e) => {
        this.setState({ remarksAF: e.target.value,isshow:false });  
    };
    handleAddApplicationHistory = (e) =>{
        let a = this.state.applicationHistoryGrid
        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        let b = moment(this.state.dateAppliedAF).format("MM/DD/YYYY");
        const obj = {
            "id"            :   newId.toString(),
            "clientName"    :   this.state.clientNameAH,
            "positionAF"      :   this.state.positionNameAH,
            "referenceNo"   :   this.state.refNoAF,
            "dateApplied"   :   this.state.dateAppliedAF,
            "formatedDate" : b,
            "remarks"       :   this.state.remarksAF,
            "isModified"    :   "0",
            "isDeleted"     :   "0",  
        };
        a.push(obj) 
        ////console.log(a)
        this.setState({
            applicationHistoryGrid : a,  
            isshow:false,
            clientNameAH : "",
            positionNameAH : "",
            refNoAF:"",
            dateAppliedAF:"",
            remarksAF : "",
        })

    };

    handleRemoveApplicationHistory = (e) =>{
        let applicationHistoryGrid = this.state.applicationHistoryGrid.filter(x=>x.isDeleted=="0")
        this.state.employmentRecordGrid = []
        this.setState({applicationHistoryGrid:applicationHistoryGrid,disableRemoveEmploymentRecord: applicationHistoryGrid.length===0 ? true : false})
    };
    fileUploadChangedHandler=(event)=>{
        //console.log(event.target.files[0])
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }

        ////console.log("event.target.files[0]")
        ////console.log(event.target.files[0])


        
        this.setState({ uploadResume : event.target.files[0]})
    }
    //w function application history
    
    //w grid function 
    /* autofillEducation=(i)=>{
        let a = this.state.educationGrid
        let b = this.state.levelDDLtwo
        
        b.forEach(element => {
           //element.id = (tmp+1).toString()
            element.SchoolName = this.state.SchoolName
            element.Course        =   this.state.Course
            element.StartYear     =   this.state.StartYear
            element.EndYear       =   this.state.EndYear
            element.HonorRecieved =   this.state.honorsAward
            element.contactPerson =   ""
            element.contactNumber =   ""
        });

        
        //console.log("autofillEducation")
        //console.log(a)
        this.setState({educationGrid : b }) 
        this.autofillFamilyBackground()
    }; */
    autofillFamilyBackground=()=>{
        let a = this.state.familyBackgroundGrid
        let b = this.state.roleDDLtwo
        let newId = moment(new Date()).format('HHMMSS');
        b.forEach((itm,idx) => {
            itm.id = (newId + (idx+1)).toString()
            itm.name = this.state.fbName
            itm.contactNumber = this.state.fbCNumber
            itm.age = this.state.fbAge
            itm.occupation = this.state.fbOccupation
            itm.company = this.state.fbCOSchool
            itm.userId = this.state.userinfo.userId
            itm.role = this.state.role
            itm.isDeleted = "0"
         })
         ////console.log("asdasdasd")
         ////console.log(b)
         
         this.setState({familyBackgroundGrid : b }) 
    }
    //w grid function 
    handleValidate = async()=>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true,isloading:true})
        const param ={
            "IpAddress"                 : "0.0.0.0",
            "ClientId"                  : this.state.clientId,
            "UserId"                    : this.state.userinfo.userId,
            "FirstName"                 : this.state.fName,
            "NickName"                  : this.state.nName,
            "MiddleName"                : this.state.mName,
            "LastName"                  : this.state.lName,
            "DateOfBirth"               : this.FormatDate(this.state.dateOfBirth),
            "TINNumber"                 : this.state.tin,
            "SSSNumber"                 : this.state.sss,
            "PHICNumber"                : this.state.phic,
            "HDMFNumber"                : this.state.pagibig,
        }

        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Recruitment/ValidateApplicant",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true,
                    name        :   ''
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    name        :   ''
                })
            })
    }
    IsValidMobile(val){
        var regex = /^0(9|4)\d{9}$/;
        return !regex.test(val)
    }
    ValidateMobileNumber = (e) =>{
        this.setState({isloading:true})
        if(this.IsValidMobile(e.target.value)){
            alert(e.target.name==="mobileNumber" ? "Mobile number should start with 09 followed by 9 digits." : "Emergency contact number should start with 09 followed by 9 digits.")
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: e.target.name==="mobileNumber" ? "Mobile number should start with 09 followed by 9 digits." : "Emergency contact number should start with 09 followed by 9 digits.",
                fade:true
            });
            return
        }
        this.setState({isloading:false})
    }
    render() {
        const { s3Objects, searchQuery, selectedFileContent, name, company, showAlert, alertMessage } = this.state;

        /* const filteredS3Objects = this.state.s3Objects.filter(object =>
            object.Key.toLowerCase().includes(this.state.searchQuery.toLowerCase())
        ); */
         const { navigate } = this.state;
        // //const { SearchBar } = Search;

        if (navigate) {
            return <Navigate to="/ApplicationFormEdit" push={true} />;
        } 

        const educationColumn = [
            {
                dataField: 'LevelId',
                text: 'Level',
                headerStyle: () => {
                    return { width: "14%" };
                },
                formatter: (cell, row) => {
                    if(row.LevelId!='' && row.LevelId!=null){
                        if(this.state.tblLevelArrLst.filter(x => x.value == cell).length==0)
                             return ""
                        else
                         return this.state.tblLevelArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblLevelArrLst
                },
                validator: (newValue, row, column) => {
                    if(this.state.educationGrid.filter(x=>x.LevelId===newValue).length>0)
                    return {
                        valid: false,
                        message:"Selected level already exist."
                      };
                    return true;   
                }
            },
            {
                dataField: 'SchoolName',
                text: 'School Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "28%" };
                  },
                  validator: (newValue, row, column) => {
                      if(row.LevelId==="")
                      return {
                          valid: false,
                          message:"Please select school level."
                        };
                      return true;   
                  }
            },
            {
                dataField: 'Course',
                text: 'Course',
                headerStyle: () => {
                    return { width: "19%" };
                  },
                  validator: (newValue, row, column) => {
                      if(row.SchoolName==="")
                      return {
                          valid: false,
                          message:"Please enter school name."
                        };
                      return true;   
                  }
            },
            {
                dataField: 'StartYear',
                text: 'Start Year',
                headerStyle: () => {
                    return { width: "9%" };
                },
                validator: (newValue, row, column) => {
                    this.setState({
                        totalValue : newValue
                    })
                    if(row.Course==="")
                        return {
                            valid: false,
                            message:"Please enter Course."
                    };
                    if (this.ValidNumeric(newValue)) {
                    return {
                        valid: false,
                        message:"Value must be numeric."
                    };
                    } 
                    return true;   
                }
            },
            {
                dataField: 'EndYear',
                text: 'End Year',
                headerStyle: () => {
                    return { width: "9%" };
                  },
                  validator: (newValue, row, column) => {
                      if(row.StartYear==="")
                          return {
                          valid: false,
                          message:"Please enter start year."
                      };
                      if (this.ValidNumeric(newValue)) {
                        return {
                          valid: false,
                          message:"Value must be numeric."
                        };
                      } 
                      return true;   
                  }
            },
            {
                dataField: 'HonorRecieved',
                text: 'Honor Recieved',
                headerStyle: () => {
                    return { width: "21%" };
                },
                validator: (newValue, row, column) => {
                    if(row.EndYear==="")
                        return {
                        valid: false,
                        message:"Please enter start year."
                    };
                    return true;   
                }
            },
            // {
            //     dataField: 'action',
            //     text: 'ACTION',
            //     editable:false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'7%'}
            //     },
            //     style:{textAlign:'center',width:'7%'},
            //     formatter   :   (cell, row, isSelect) => {
            //         return (
            //             <Button 
            //                 onClick={e => this.handleRemoveEducation(row.id)}
            //                 style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
            //                 variant="link"
            //         >REMOVE</Button>
            //         );
            //     },
            // }
        ]

        const educationSelectRow = {
            mode: '',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };

        const employmentRecordColumn = [
            {
                dataField: 'CompanyName',
                text: 'Company_Name',
                headerStyle: () => {
                    return { width: "20%" };
                }
                
            },
            {
                dataField: 'CompanyAddress',
                text: 'Company_Address',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            {
                dataField: 'Position',
                text: 'Position',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            /* {
                dataField: 'periodCovered',
                text: 'Period_Covered',
                headerStyle: () => {
                    return { width: "20%" };
                }
            }, */
            {
                dataField: 'FromStart',
                text: 'from start',
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'ToEnd',
                text: 'to end',
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'ReasonForLeaving',
                text: 'reason for leaving',
                headerStyle: () => {
                    return { width: "25%" };
                }
            },
            {
                dataField: 'Salary',
                text: 'Salary',
                headerStyle: () => {
                    return { width: "20%" };
                },
                style:{textAlign:'right',whiteSpace:'nowrap'}
            },
        ]

        const employmentRecordSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.employmentRecordGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEmploymentRecord:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.employmentRecordGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableRemoveEmploymentRecord:isDisable})
            }
        };

        // const applicationHistorySelectRow = {
        //     mode: 'checkbox',
        //     clickToSelectAndEditCell: true,
        //     onSelect: (row, isSelect, rowIndex, e) => {
        //         let isDisable = true
        //         this.state.applicationHistoryGrid.map(function(item,i){
        //             if(item.id===row.id)
        //                 item.isDeleted = isSelect ? "1" : "0"
        //             if(item.isDeleted=="1")
        //                 isDisable=false
        //         })
        //         this.setState({disableRemoveEmploymentRecord:isDisable})
        //     },
        //     onSelectAll: (isSelect, rows) => {
        //         let isDisable = true
        //         this.state.applicationHistoryGrid.map(function(itm){
        //             itm.isDeleted=isSelect ? "1" : "0"
        //             if(itm.isDeleted=="1")
        //                 isDisable=false
        //         })
        //         this.setState({disableRemoveEmploymentRecord:isDisable})
        //     }
        // };

        

        const familyBackgroundColumn = [
            {
                dataField: 'RoleId',
                text: 'Role',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.RoleId!='' && row.RoleId!=null){
                        return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst.filter(x=>parseInt(x.value)>2)
                },
                validator: (newValue, row, column) => {
                    
                    if(this.state.familyBackgroundGrid.filter(x=>x.RoleId===newValue).length>0 && newValue==="0")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role father."
                      };
                    if(this.state.familyBackgroundGrid.filter(x=>x.RoleId===newValue).length>0 && newValue==="1")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role mother."
                    };
                    if(this.state.familyBackgroundGrid.filter(x=>x.RoleId===newValue).length>0 && newValue==="2")
                    return {
                        valid: false,
                        message:"Cannot set multiple for role spouse."
                    };
                    return true;   
                }
            },
            {
                dataField: 'Name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "20%" };
                },
                editCellStyle: {
                  fontWeight: 'bold'
                }
            },
            {
                dataField: 'ContactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "15%" };
                },
                validator: (newValue, row, column) => {
                    if(this.IsValidMobile(newValue))
                    return {
                        valid: false,
                        message:'Contact number should start with 09 followed by 9 digits.'
                      };
                    return true;
                },
            },
            {
                dataField: 'Age',
                text: 'Age',
                headerStyle: () => {
                    return { width: "10%" };
                    }
            },
            {
                dataField: 'Occupation',
                text: 'Occupation',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
            {
                dataField: 'Company',
                text: 'Company',
                headerStyle: () => {
                    return { width: "20%" };
                }
            },
        ]

        const familyBackgroundSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.familyBackgroundGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBackground:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.familyBackgroundGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableBackground:isDisable})
            }
        };

        // const othersColumn = [
        //     {
        //         dataField: 'jobSourceId',
        //         text: 'Job Opening',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //         },
        //         formatter: (cell, row) => {
        //             if(row.jobSourceId!='' && row.jobSourceId!=null){
        //                 return this.state.tblJobOpeningArrLst.find(x => x.value == cell).label;
        //             }
        //         },
        //         /* editor: {
        //             type: Type.SELECT,
        //             options: this.state.tblJobOpeningArrLst
        //         } */
        //         /* headerStyle: () => {
        //             return { width: "10%" };
        //         },
        //         formatter: (cell, row) => {
        //             if(row.RoleId!='' && row.RoleId!=null){
        //                 return this.state.tblRoleArrLst.find(x => x.value == cell).label;
        //             }
        //         },
        //         editor: {
        //             type: Type.SELECT,
        //             options: this.state.tblRoleArrLst
        //         } */
        //     },
        //     {
        //         dataField: 'refferedBy',
        //         text: 'Preffered By',
        //         headerStyle: () => {
        //             return { width: "15%" };
        //             }
        //     },
        //     /* {
        //         dataField: 'presentlyConnected',
        //         text: 'Presently Connected',
        //         headerStyle: () => {
        //             return { width: "5%" };
        //             }
        //     }, */
        //     {
        //         dataField: 'relativeName',
        //         text: 'Name',
        //         headerStyle: () => {
        //             return { width: "15%" };
        //             }
        //     },
        //     {
        //         dataField: 'relativeRelation',
        //         text: 'Relationship',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //             }
        //     },
        //     {
        //         dataField: 'relativePosition',
        //         text: 'Job Title',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //             }
        //     },
        //     {
        //         dataField: 'contactNumber',
        //         text: 'Contact Number',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //             }
        //     },
        //     /* {
        //         dataField: 'convictedWithACrime',
        //         text: 'Convicted With A Crime',
        //         headerStyle: () => {
        //             return { width: "5%" };
        //             }
        //     }, */
        //     {
        //         dataField: 'crimeDescription',
        //         text: 'Give Details Crime',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //             }
        //     },
        //     /* {
        //         dataField: 'labourUnion',
        //         text: 'Member Of A Labour Union',
        //         headerStyle: () => {
        //             return { width: "5%" };
        //             }
        //     }, */
        //     {
        //         dataField: 'laborUnionDescription',
        //         text: 'Give Details Union',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //             }
        //     },
        //     {
        //         dataField: 'diagnoseDeseaseId',
        //         text: 'Suffered or Diagnosed',
        //         headerStyle: () => {
        //             return { width: "10%" };
        //         },
        //         formatter: (cell, row) => {
        //             if(row.diagnoseDeseaseId!='' && row.diagnoseDeseaseId!=null){
        //                 return this.state.tblDiseaseArrLst.find(x => x.value == cell).label;
        //             }
        //         },
        //        /*  editor: {
        //             type: Type.SELECT,
        //             options: this.state.tblDiseaseArrLst
        //         } */
        //     },
        // ]

        // const othersSelectRow = {
        //     mode: 'checkbox',
        //     clickToSelectAndEditCell: true,
        //     onSelect: (row, isSelect, rowIndex, e) => {
        //         let isDisable = true
        //         this.state.othersGrid.map(function(item,i){
        //             if(item.id===row.id)
        //                 item.isDeleted = isSelect ? "1" : "0"
        //             if(item.isDeleted=="1")
        //                 isDisable=false
        //         })
        //         this.setState({disableOthers:isDisable})
        //     },
        //     onSelectAll: (isSelect, rows) => {
        //         let isDisable = true
        //         this.state.othersGrid.map(function(itm){
        //             itm.isDeleted=isSelect ? "1" : "0"
        //             if(itm.isDeleted=="1")
        //                 isDisable=false
        //         })
        //         this.setState({disableOthers:isDisable})
        //     }
        // };

        const referenceColumn = [
            {
                dataField: 'TypeId',
                text: 'Type of Character Reference',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.TypeId!='' && row.TypeId!=null){
                        return this.state.tbltypeofcharacterreferenceArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tbltypeofcharacterreferenceArrLst
                }
            },
            {
                dataField: 'Name',
                text: 'Name',
                headerStyle: () => {
                    return { width: "25%" };
                  }
            },
            {
                dataField: 'ContactNumber',
                text: 'Contact Number',
                headerStyle: () => {
                    return { width: "20%" };
                  },
                validator: (newValue, row, column) => {
                    if(this.IsValidMobile(newValue))
                    return {
                        valid: false,
                        message:'Contact number should start with 09 followed by 9 digits.'
                      };
                    return true;
                },
            },
            {
                dataField: 'Occupation',
                text: 'Occupation',
                headerStyle: () => {
                    return { width: "25%" };
                  }
            },
            {
                dataField: 'Company',
                text: 'Company',
                headerStyle: () => {
                    return { width: "30%" };
                  }
            },
        ]

        const referenceSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.referenceGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableReference:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.referenceGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableReference:isDisable})
            }
        };

        // const APPLICATIONHISTORYColumn = [
        //     {
        //         dataField: 'clientName',
        //         text: 'Client Name',
        //         headerStyle: () => {
        //             return { width: "25%" };
        //         }
                
        //     },
        //     {
        //         dataField: 'positionAF',
        //         text: 'Position',
        //         headerStyle: () => {
        //             return { width: "25%" };
        //         }
        //     },
        //     {
        //         dataField: 'referenceNo',
        //         text: 'reference No',
        //         headerStyle: () => {
        //             return { width: "25%" };
        //         }
        //     },
        //     {
        //         dataField: 'formatedDate',
        //         text: 'date Applied',
        //         headerStyle: () => {
        //             return { width: "25%" };
        //         },
                
        //     },
            
        // ]


        return(
            <div>
            {/* <Banner /> */}
                <Container  className="mt-3 " fluid={true}>
                    <Row className='mt-5'></Row>
                    <Row className='mt-5'></Row>
                    <Row className='mt-3'></Row>
                    <Row className='mt-5'></Row>
                    
                    <Card style={{borderColor : 'grey'}} className="appFormBackground1">
                        <Card.Header style={{backgroundColor : "green",color : 'white'}}>CANDIDATE PROFILE (VIEW)</Card.Header>
                        <Card.Body className="appFormBackground1">
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                {/* <Row>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                        <Typeahead 
                                            ref='clientref'
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client" 
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                        <Typeahead
                                            ref='positionref'
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangePosition}
                                            options={this.state.positionDDL}
                                            placeholder="Select Position" 
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE APPLIED</Form.Label>
                                        <Form.Group as={Col} controlId="formGridCity">
                                        <DatePicker
                                            ref='dateApplied'
                                            selected={this.state.dateApplied}
                                            onChange={this.handleChangeDateApplied}
                                            minDate={this.minDate}
                                            value={this.props.dateApplied}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date Applied" 
                                        />
                                        </Form.Group>
                                    </Form.Group>
                                </Row> */}
                                <Tabs style={{borderColor : 'grey'}} className="mt-2 tabColor" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                    <Tab className='tabColor' eventKey="default" title="I. GENERAL INFORMATION">
                                        <Card className="card-tab-no-border">
                                            <Card.Body className="appFormBackground1">
                                                
                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}></Form.Label>
{/*                                                             <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeTitle}
                                                            value={this.state.titleValue}
                                                        >
                                                        {this.state.titleDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control> */}
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        {/* <input type="file" name='newImage' required onChange={this.fileChangedHandler} /> */}
                                                        
                                                        <div className='mt-2  photoAFC'>
                                                            
                                                                <div className='aa'>
                                                                    <div /* isOpen={this.state.isshow} */ color={this.state.color} fade={this.state.fade} className={this.state.photoLabel ? 'display-block' : 'display-none'}>
                                                                        <h1 className=' mt-5 fontcolorwhite'>2 X 2 </h1>
                                                                        <Label className='  photolabelAFC'>2 X 2 photo</Label>
                                                                        <h1  className=' fontcolorwhite'> 2 X 2</h1>
                                                                    </div>
                                                                    <div isOpen={this.state.isshow} 
                                                                        color={this.state.color} 
                                                                        fade={this.state.fade} 
                                                                        className={this.state.newImageShow ? 'display-block' : 'display-none'}  
                                                                    >
                                                                        <img /* className='photoAdjust' */ 
                                                                            src={this.state.newImage} alt="" 
                                                                            style={{height : "200px", width : "200px"}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            
                                                        </div>
                                                    </Form.Group>
                                                </Row>

                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}>TITLE</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeTitle}
                                                            value={this.state.titleValue}
                                                            readOnly
                                                            disabled
                                                        >
                                                        {this.state.titleDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>NICK NAME</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="nName"
                                                            value={this.state.nName}
                                                            onChange={this.onChangeNickName} 
                                                            autoComplete="off"
                                                            placeholder="Enter Nick name" 
                                                            onKeyPress={this.handleNickNameDoubleSpaces}
                                                            readOnly                                                            
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>FIRST NAME</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="fName"
                                                            value={this.state.fName}
                                                            onChange={this.onChangeFirstName} 
                                                            autoComplete="off"
                                                            placeholder="Enter First name" 
                                                            onKeyPress={this.handleFirstNameDoubleSpaces}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>MIDDLE NAME</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="mName"
                                                            value={this.state.mName}
                                                            onChange={this.onChangeMiddleName} 
                                                            autoComplete="off"
                                                            placeholder="Enter Middle name" 
                                                            onKeyPress={this.handleMiddleNameDoubleSpaces}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>LAST NAME</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="lName"
                                                            value={this.state.lName}
                                                            onChange={this.onChangeLastName} 
                                                            autoComplete="off"
                                                            placeholder="Enter Last name" 
                                                            onKeyPress={this.handleLastNameDoubleSpaces}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>SUFFIX NAME</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.onChangeSuffix}
                                                            value={this.state.sName}
                                                            disabled
                                                        >
                                                        {this.state.suffixDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                        {/* <Form.Control 
                                                            type="text"
                                                            name="sName"
                                                            value={this.state.sName}
                                                            onChange={this.onChangeSuffixName} 
                                                            autoComplete="off"
                                                            placeholder="Enter Suffix name" 
                                                        /> */}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE OF BIRTH</Form.Label>
                                                        <Form.Group as={Col} controlId="formGridCity">
                                                            <DatePicker
                                                                ref='dateOfBirth'
                                                                selected={this.state.dateOfBirth}
                                                                onChange={this.handleChangeDateOfBirth}
                                                                minDate={this.minDate}
                                                                value={this.props.dateOfBirth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                placeholderText="Select Date Of Birth "
                                                                className="form-control"
                                                                wrapperClassName="datepicker"
                                                                readOnly
                                                                //showMonthDropdown
                                                                //showYearDropdown
                                                                //dropdownMode= "scroll"
                                                                
                                                            />
                                                        </Form.Group>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}>AGE</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="totalAge"
                                                            value={this.state.totalAge}
                                                            onChange={this.onChangeAgeMonth.bind(this)}
                                                            autoComplete="off"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>SSS #</Form.Label>
                                                            <PatternFormat
                                                                name="sss"
                                                                value={this.state.sss}
                                                                onChange={this.onChangeSSS} 
                                                                autoComplete="off"
                                                                format="##-#######-#"
                                                                className="form-control"
                                                                placeholder="Enter SSS Number"
                                                                readOnly
                                                            />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>TIN #</Form.Label>
                                                        <PatternFormat
                                                            name="tin"
                                                            value={this.state.tin}
                                                            onChange={this.onChangeTin} 
                                                            autoComplete="off"
                                                            format="###-###-###-###"
                                                            className="form-control"
                                                            placeholder="Enter TIN Number"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>PHILHEALTH #</Form.Label>
                                                        <PatternFormat
                                                            name="phic"
                                                            value={this.state.phic}
                                                            onChange={this.onChangePhilHealth} 
                                                            autoComplete="off"
                                                            format="##-#########-#"
                                                            className="form-control"
                                                            placeholder="Enter PHILHEALTH Number"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>PAGIBIG #</Form.Label>
                                                        <PatternFormat
                                                            name="pagibig"
                                                            value={this.state.pagibig}
                                                            onChange={this.onChangePagibig} 
                                                            autoComplete="off"
                                                            format="####-####-####"
                                                            className="form-control"
                                                            placeholder="Enter PAGIBIG Number"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                <Form.Group as={Col} controlId="formGridCity">
                                               {/*  <ButtonToolbar >
                                                    <Button variant="success" style={{width:'100%'}} onClick = {() => this.handleValidate()}>Check Applicant Name / Mandatory Number</Button>
                                                </ButtonToolbar> */}
                                                </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>TYPE</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeType}
                                                            value={this.state.typeId}
                                                            disabled
                                                        >
                                                        {this.state.typeDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>REGION</Form.Label>
                                                            <Typeahead
                                                               labelKey='name'
                                                               name="regionId"
                                                               id="regionId"
                                                               onChange={this.handleChangeRegion}
                                                               options={this.state.regionDDL}
                                                               //defaultInputValue={this.state.region}
                                                              // defaultSelected={this.state.region}
                                                               selected={[this.state.region]}
                                                               placeholder="Select Region"
                                                               readOnly
                                                               disabled
                                                            />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>PROVINCE</Form.Label>
                                                            <Typeahead
                                                                 //ref='provinceref'
                                                                 labelKey='name'
                                                                 id="basic-example"
                                                                 onChange={this.handleChangeProvince}
                                                                 options={this.state.provinceDDL}
                                                                 placeholder="Select Province"
                                                                 //defaultSelected={[this.state.provinceName]}
                                                                 selected={[this.state.provinceName]}
                                                                 readOnly
                                                                 disabled
                                                            />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>CITY/MUNICIPALITY</Form.Label>
                                                            <Typeahead    
                                                               labelKey='name'
                                                               id="basic-examplex"
                                                               onChange={this.handleChangeCity}
                                                               options={this.state.cityDDL}
                                                               placeholder="Select City"
                                                               //defaultSelected={[this.state.cityName]}
                                                               selected={[this.state.cityName]}
                                                               readOnly
                                                               disabled
                                                            />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HOUSE NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="houseNumber"
                                                            value={this.state.houseNumber}
                                                            placeholder="Enter House Number"
                                                            onChange={this.onChangeHouseNumber} 
                                                            autoComplete="off"  
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>STREET</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="street"
                                                            value={this.state.street}
                                                            placeholder="Enter Street"
                                                            onChange={this.onChangeStreet} 
                                                            autoComplete="off"  
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>BARANGAY</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="barangay"
                                                            value={this.state.barangay}
                                                            placeholder="Enter barangay"
                                                            onChange={this.onChangeBarangay} 
                                                            autoComplete="off"  
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>POSTAL CODE</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="postalCode"
                                                            value={this.state.postalCode}
                                                            placeholder="Enter Postal Code"
                                                            onChange={this.onChangePostalCode} 
                                                            autoComplete="off"  
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HOME PHONE NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="hpNumber"
                                                            value={this.state.hpNumber}
                                                            placeholder="Enter Home Phone Number"
                                                            onChange={this.onChangeHPNumber} 
                                                            autoComplete="off"        
                                                            maxLength="8"  
                                                            readOnly
                                                        />
                                                        {/* <NumberFormat
                                                            name="hpNumber"
                                                            value={this.state.hpNumber}
                                                            onChange={this.onChangeHPNumber} 
                                                            autoComplete="off"
                                                            format="####-####"
                                                            className="form-control"
                                                            placeholder="Enter Home Phone Number"
                                                        /> */}
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>OFFICE PHONE NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="opNumber"
                                                            value={this.state.opNumber}
                                                            placeholder="Enter Office Phone Number"
                                                            onChange={this.onChangeOPNumber} 
                                                            autoComplete="off"        
                                                            maxLength="8"   
                                                            readOnly
                                                        />
                                                        {/* <NumberFormat
                                                            name="opNumber"
                                                            value={this.state.opNumber}
                                                            onChange={this.onChangeOPNumber} 
                                                            autoComplete="off"
                                                            format="####-####"
                                                            className="form-control"
                                                            placeholder="Enter Office Phone Number"
                                                        /> */}
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>MOBILE NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="mobileNumber"
                                                            value={this.state.mobileNumber}
                                                            placeholder="Enter Mobile Number"
                                                            onChange={this.onChangeMobileNumber} 
                                                            autoComplete="off"          
                                                            maxLength="11"
                                                            //onKeyPress={this.IsNumeric.bind(this)}
                                                            onBlur={this.ValidateMobileNumber}
                                                            readOnly
                                                        />
                                                        {/* <NumberFormat
                                                            name="mobileNumber"
                                                            value={this.state.mobileNumber}
                                                            onChange={this.onChangeMobileNumber} 
                                                            autoComplete="off"
                                                            format="####-####-###"
                                                            className="form-control"
                                                            placeholder="Enter Mobile Number"
                                                        /> */}
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formBasicEmail">
                                                        <Form.Label style={{fontWeight : "bold"}}>EMAIL ADDRESS</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="emailAddress"
                                                            value={this.state.emailAddress}
                                                            placeholder="Enter Email Address"
                                                            onChange={this.onChangeEmailAddress} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>FACEBOOK</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="facebook"
                                                            value={this.state.facebook}
                                                            placeholder="Enter Facebook"
                                                            onChange={this.onChangeFacebook} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>TWITTER</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="twitter"
                                                            value={this.state.twitter}
                                                            placeholder="Enter Twitter"
                                                            onChange={this.onChangeTwitter} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}>CITIZENSHIP</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeCitizenship}
                                                            value={this.state.nationalityId}
                                                            readOnly
                                                            disabled
                                                        >
                                                        {this.state.nationalityDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}>RELIGION</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeReligion}
                                                            value={this.state.religionId}
                                                            readOnly
                                                            disabled
                                                        >
                                                        {this.state.religionDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}>GENDER</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeGender}
                                                            value={this.state.genderValue}
                                                            readOnly
                                                            disabled
                                                        >
                                                        {this.state.genderDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label style={{fontWeight : "bold"}}>CIVIL STATUS</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeCivilStatus}
                                                            value={this.state.civilStatusValue}
                                                            readOnly
                                                            disabled
                                                        >
                                                        {this.state.civilStatusDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Row> 
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>LIVING ARRANGEMENT</Form.Label>
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='arrangementId'
                                                            name='arrangementId'
                                                            options={this.state.arrangementDDL}
                                                            onChange={this.handleChangedArrangement}
                                                            placeholder="Select Living Arrangment"
                                                            selected={[this.state.arrangement]}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>BLOOD TYPE</Form.Label>
                                                        <Typeahead
                                                             labelKey='name'
                                                             id='bloodtypeId'
                                                             name='bloodtypeId'
                                                             options={this.state.bloodtypeDDL}
                                                             onChange={this.handleChangedBloodType}
                                                             placeholder="Select Blood Type"
                                                             selected={[this.state.bloodtype]}
                                                             readOnly
                                                             disabled
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>PLACE OF BIRTH</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="placeOfBirth"
                                                            value={this.state.placeOfBirth}
                                                            placeholder="Enter Place Of Birth"
                                                            onChange={this.onChangePlaceOfBirth} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HEIGHT</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="height"
                                                            value={this.state.height}
                                                            placeholder="Enter Height"
                                                            onChange={this.onChangeHeight} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>WEIGHT</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="weight"
                                                            value={this.state.weight}
                                                            placeholder="Enter Weight"
                                                            onChange={this.onChangeWeight} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>SPECIAL SKILLS</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="sSkills"
                                                            value={this.state.sSkills}
                                                            placeholder="Enter Special Skills"
                                                            onChange={this.onChangeSkills} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>LANGUAGE SPOKEN</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="lSpoken"
                                                            value={this.state.lSpoken}
                                                            placeholder="Enter Language Spoken"
                                                            onChange={this.onChangeLSpoken} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>EMERGENCY CONTACT NAME</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="ecName"
                                                            value={this.state.ecName}
                                                            placeholder="Enter Emergency Contact Name"
                                                            onChange={this.onChangeECName} 
                                                            autoComplete="off"
                                                            readOnly
                                                            //maxLength="11"
                                                            //onKeyPress={this.IsNumeric.bind(this)}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>RELATIONSHIP</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="relationship"
                                                            value={this.state.relationship}
                                                            placeholder="Enter Relationship"
                                                            onChange={this.onChangeRelationship} 
                                                            autoComplete="off"
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>EMERGENCY CONTACT NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="ecNumber"
                                                            value={this.state.ecNumber}
                                                            placeholder="Enter Emergency Contact Number"
                                                            onChange={this.onChangeECNumber} 
                                                            autoComplete="off"         
                                                            maxLength="11" 
                                                            onBlur={this.ValidateMobileNumber}
                                                            readOnly
                                                        />
                                                        {/* <NumberFormat
                                                            name="ecNumber"
                                                            value={this.state.ecNumber}
                                                            onChange={this.onChangeECNumber} 
                                                            autoComplete="off"
                                                            format="####-####-###"
                                                            className="form-control"
                                                            placeholder="Enter Emergency Contact Number"
                                                        /> */}
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        {/* <Form.Label style={{fontWeight : "bold"}}>UPLOAD RESUME</Form.Label> &nbsp;&nbsp;&nbsp; */}
                                                        {/* <input type="file" name='uploadResume' onChange={this.fileUploadChangedHandler} /> */}
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}></Form.Label> &nbsp;&nbsp;&nbsp;
                                                        {/* <input type="file" onChange={this.fileUploadChangedHandler} /> */}
                                                    </Form.Group>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    
                                    <Tab eventKey="education" title="II. EDUCATION">
                                        <Card className="card-tab-no-border">
                                            <Card.Body className="appFormBackground1">
                                                
                                                    
                                                {/* <ButtonToolbar className="mt-3">
                                                    <Button variant="success" onClick = { this.handleAddEducation }>Add</Button>
                                                    <Button className="noser-button-mr1" disabled={this.state.disableRemoveEducation} className="noser-button" variant="danger" onClick = { this.handleRemoveEducation }>REMOVE</Button>
                                                </ButtonToolbar> */}
                                                <div className="mt-1">  
                                                    <div>
                                                        <Card.Header>EDUCATION LIST</Card.Header>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.educationGrid }
                                                            columns = { educationColumn }
                                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            rowClasses="noser-table-row-class no-checkbox"
                                                            striped
                                                            hover
                                                            condensed
                                                            /* cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.EducationGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            } */
                                                            //selectRow = { educationSelectRow }
                                    
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                            
                                    </Tab>

                                    <Tab eventKey="employment" title=" III. EMPLOYMENT RECORD (START WITH LATEST EMPLOYER)">
                                        <Card className="card-tab-no-border">
                                            <Card.Body className="appFormBackground1">
                                                
                                                <div className="mt-1">  
                                                    <div>
                                                        <Card.Header>EMPLOYMENT RECORDS LIST</Card.Header>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.employmentRecordGrid }
                                                            columns = { employmentRecordColumn }
                                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            /* cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.EmploymentGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            } */
                                                            //selectRow = { employmentRecordSelectRow }
                                    
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                            
                                    </Tab>


                                    <Tab eventKey="familybackground" title="IV. FAMILY BACKGROUND">
                                        <Card className="card-tab-no-border">
                                            <Card.Body className="appFormBackground1">
                                                
                                                {/* <ButtonToolbar className="mt-3">
                                                    <Button size="sm" variant="success" onClick = { this.handleAddFamilybackground }>Add</Button>&nbsp; &nbsp;
                                                    <Button size="sm" disabled={this.state.disableBackground} className="noser-button" variant="danger" onClick = { this.handleRemoveBackground }>REMOVE</Button>
                                                </ButtonToolbar> */}
                                                <div className="mt-1">  
                                                    <div>
                                                        <Card.Header>Family Background List</Card.Header>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.familyBackgroundGrid }
                                                            columns = { familyBackgroundColumn }
                                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            // cellEdit = { cellEditFactory({
                                                            //     mode: 'dbclick',
                                                            //     blurToSave: true,
                                                            //     afterSaveCell: (oldValue, newValue, row, column) => {
                                                            //         this.FamilyBackgroundGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            //         }
                                                            //     })
                                                            // }
                                                            // selectRow = { familyBackgroundSelectRow }
                                    
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>   
                                    </Tab>
                                                            
                                    <Tab eventKey="others" title="V. OTHERS">
                                        <Card className="card-tab-no-border">
                                            <Card.Body className="appFormBackground1">
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HOW DID YOU LEARN ABOUT THIS JOB OPENING ?</Form.Label>
                                                        <Form.Control
                                                                as="select"
                                                                onChange={this.handleChangeJobOpening}
                                                                value={this.state.jobSourceId}
                                                                disabled
                                                            >
                                                            {this.state.jobOpeningDDL.map(item => (
                                                                <option key={item.value}  value={item.value} >
                                                                {item.name}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>REFERRED BY</Form.Label>
                                                        <div>
                                                            <div /* isOpen={this.state.isshow} */ color={this.state.color} fade={this.state.fade} className={this.state.referealOthersTA ? 'display-block' : 'display-none'}>
                                                                <Typeahead
                                                                    ref="refReferredBy"
                                                                    labelKey='employeeName'
                                                                    id="basic-example"
                                                                    onChange={this.handleChangeReferral}
                                                                    options={this.state.referralDDL}
                                                                    placeholder="Select Referral"
                                                                    disabled={this.state.disablereferral}
                                                                    onInputChange={this.handledOnSearch}
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div color={this.state.color} fade={this.state.fade} className={this.state.referealOthersTXT ? 'display-block' : 'display-none'}>
                                                                <Form.Control 
                                                                    type="text"
                                                                    name="oReferredBy"
                                                                    value={this.state.oReferredBy}
                                                                    onChange={this.onChangeOthersRefferedBy} 
                                                                    autoComplete="off"
                                                                    placeholder="Enter Reffered By" 
                                                                    disabled={this.state.disablereferral}
                                                                    readOnly
                                                                    disable
                                                                /> 
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </Row>
                                                <hr/>
                                                <div style={{height : 5}}></div>
                                                <Row className="mt-3">
                                                    <Form.Group as={Col} sm="5" controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>DO YOU HAVE RELATIVES OR FRIENDS PRESENTLY CONNECTED WITH THIS COOPERATIVE ?</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formBasicCheckbox">
                                                        <Form.Check
                                                            inline 
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="checkORelativeYes"
                                                            checked={this.state.checkORelativeYes}
                                                            //defaultChecked={this.state.checkORelativeYes}
                                                            onChange={this.onChangeORelativeYes}
                                                            style={{fontWeight : "bold", marginTop : "3px"}}
                                                            readOnly
                                                            disable
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="checkORelativeNo"
                                                            checked={this.state.checkORelativeNo}
                                                            onChange={this.onChangeORelativeNo}
                                                            style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                            readOnly
                                                            disable
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>NAME</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="oRelativeName"
                                                            value={this.state.oRelativeName}
                                                            onChange={this.onChangeORelativeName} 
                                                            autoComplete="off"
                                                            placeholder="Enter NAME" 
                                                            //disabled={ this.state.disabledRelative}
                                                            readOnly
                                                            disable
                                                            
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>CONTACT NUMBER</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="oCNumber"
                                                            value={this.state.oCNumber}
                                                            placeholder="Enter Contact Number"
                                                            onChange={this.onChangeOCNumber} 
                                                            autoComplete="off"  
                                                            //disabled={ this.state.disabledRelative}            
                                                            maxLength="11" 
                                                            readOnly
                                                            disable
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>RELATIONSHIP</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="oRelationship"
                                                            value={this.state.oRelationship}
                                                            onChange={this.onChangeORelationship} 
                                                            autoComplete="off"
                                                            placeholder="Enter Relationship"
                                                           // disabled={ this.state.disabledRelative}
                                                            readOnly
                                                            disable
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>JOB TITLE</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="oJobTitle"
                                                            value={this.state.oJobTitle}
                                                            onChange={this.onChangeOJobTitle} 
                                                            autoComplete="off"
                                                            placeholder="Enter Job Title"
                                                            //disabled={ this.state.disabledRelative}
                                                            readOnly
                                                            disable
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <hr/>
                                                <div style={{height : 5}}></div>
                                                <Row className="mt-3">
                                                    <Form.Group as={Col} sm="3" controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HAVE YOU BEEN CONVICTED OF A CRIME ?</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formBasicCheckbox">
                                                        <Form.Check
                                                            inline 
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="checkConvictedYes"
                                                            checked={this.state.checkConvictedYes}
                                                            onChange={this.onChangeOthersConvictedYes}
                                                            style={{fontWeight : "bold", marginTop : "3px"}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="checkConvictedNo"
                                                            checked={this.state.checkConvictedNo}
                                                            onChange={this.onChangeOthersConvictedNo}
                                                            style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, PLEASE GIVE DETAILS</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="oCrime"
                                                            value={this.state.oCrime}
                                                            onChange={this.onChangeOCrime} 
                                                            autoComplete="off"
                                                            placeholder="Enter If Yes, Please Give Details"
                                                            disabled={ this.state.disabledConvicted}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <hr/>
                                                <div style={{height : 5}}></div>
                                                <Row className="mt-3">
                                                    <Form.Group as={Col} sm="3" controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HAVE YOU BEEN A MEMBER OF A LABOR UNION ?</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formBasicCheckbox">
                                                        <Form.Check
                                                            inline 
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="checkLaborUnionYes"
                                                            checked={this.state.checkLaborUnionYes}
                                                            onChange={this.onChangeOthersLaborUnionYes}
                                                            style={{fontWeight : "bold", marginTop : "3px"}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="checkLaborUnionNo"
                                                            checked={this.state.checkLaborUnionNo}
                                                            onChange={this.onChangeOthersLaborUnionNo}
                                                            style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>IF YES, GIVE DETAILS</Form.Label>
                                                        <Form.Control 
                                                            type="text"
                                                            name="luDetails"
                                                            value={this.state.luDetails}
                                                            onChange={this.onChangeLUDetails} 
                                                            autoComplete="off"
                                                            placeholder="Enter If Yes, Give Details"
                                                           // disabled={ this.state.disabledLaborUnion}
                                                            readOnly
                                                            disable
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <hr/>
                                                <div style={{height : 5}}></div>
                                                <Row className="mt-3">
                                                    <Form.Group as={Col} sm="6" controlId="formGridCity">
                                                        <Form.Label style={{fontWeight : "bold"}}>HAVE YOU SUFFERED OR BEEN DIAGNOSED TO HAVE ANY OF THE FF. CHRONIC DISEASE(S) OR LIKES ?</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formBasicCheckbox">
                                                        <Form.Check
                                                            inline 
                                                            type="checkbox" 
                                                            label="YES" 
                                                            name="checkChronicDiseaseYes"
                                                            checked={this.state.checkChronicDiseaseYes}
                                                            onChange={this.onChangeOthersChronicDiseaseYes}
                                                            style={{fontWeight : "bold", marginTop : "3px"}}
                                                        />
                                                        <Form.Check 
                                                            inline
                                                            type="checkbox" 
                                                            label="NO" 
                                                            name="checkChronicDiseaseNo"
                                                            checked={this.state.checkChronicDiseaseNo}
                                                            onChange={this.onChangeOthersChronicDiseaseNo}
                                                            style={{fontWeight : "bold", marginTop : "3px", marginLeft : 12}}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <Row >
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.handleChangeDisease}
                                                            value={this.state.diagnoseDeseaseId}
                                                            //disabled = { this.state.disabledDisease} 
                                                            //readOnly
                                                            disable
                                                        >
                                                        {this.state.diseaseDDL.map(item => (
                                                            <option key={item.value} value={item.value}>
                                                            {item.name}
                                                            </option>
                                                        ))}
                                                        
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                            
                                    </Tab>
                                    
                                    <Tab eventKey="reference" title="VI. REFERENCES (NOT RELATED TO YOUR FAMILY)">
                                        <Card className="card-tab-no-border">
                                            <Card.Body className="appFormBackground1">
                                            <Card.Header>Reference List</Card.Header>
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.referenceGrid }
                                                            columns = { referenceColumn }
                                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            // cellEdit = { cellEditFactory({
                                                            //     mode: 'dbclick',
                                                            //     blurToSave: true,
                                                            //     afterSaveCell: (oldValue, newValue, row, column) => {
                                                            //         this.ReferenceGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            //         }
                                                            //     })
                                                            // }
                                                            //selectRow = { referenceSelectRow }
                                    
                                                        />
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>

                               
                                
                                <Row>
                                    <Col sm={9}></Col>
                                    <Col sm={1}></Col>
                                   
                                    &nbsp;
                                    <Col >
                                        <ButtonToolbar className="mt-3 ml-auto" style={{marginLeft: "30%"}}>
                                            {/* <Button disabled={this.state.disablebutton}  className="ml-auto noser-button-mr1" variant="success" onClick = {() => this.handleClickStatus('1')}>SAVE AS DRAFT</Button> */}
                                            <Button /* disabled={this.state.disablebutton} */ className="ml-auto" size="sm"   variant="success" onClick = {() => this.handleClickStatus('2')}>UPDATE</Button>&nbsp;&nbsp;
                                           {/*  <Button  href="/sourcing" className="" size="sm" variant="danger">BACK</Button> */}
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                                
                            </Form>
                        </Card.Body>
                    </Card>
                    <div style={{height : 15}}></div>
                </Container>
                {/* <NoserLoading show={this.state.isloading} /> */}
        </div>
        )
    }

}

export  default ApplicationFormView;