import React, { useState,useEffect,useRef  } from "react";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Spinner,
  Alert,
  Button,
  CardTitle,
  CardText,
  FormText
} from "reactstrap";
import axios from 'axios';
import { AppConfiguration } from "read-appsettings-json";
import moment from 'moment';
import useForm from "./useForm";
import Switch from "react-switch";
import ButtonSignin from 'react-bootstrap/Button';
import Modals from 'react-bootstrap/Modal';



import { Link } from "react-router-dom";
import {  Card,   CardBody } from "reactstrap";

import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";

import signUpImage from "../../assets/images/auth/sign-up.png";
//import useForm from "../../wcomponents/SignUp/useForm";
import Icon from "../../wcomponents/Icon/Icon";
import "../../wcomponents/w.css"
import NavBar from "../../Layout/CommonLayout/NavBar"//"../CommonLayout/NavBar";
import ScrolltoTop from "../../components/ScrolltoTop";
import TopBar from "../../Layout/CommonLayout/TopBar";
import { setSelection } from "@testing-library/user-event/dist/cjs/event/selection/setSelection.js";
import { useNavigate } from "react-router-dom";

const FormSignup = ({submitForm }) => {
    const [passState, setPassState] = useState(false);
    const [passState1, setPassState1] = useState(false);
    const { handleChange, handleSubmit, values,loading,errors, errorVal,isChecked,isEmployer,dissableBtn,handleCheckBox,handleEmployer,modal,openModal,handleGetUserCollection } = useForm(
      submitForm,
      /* validate */
    );
    //w start
    const [show, setShow] = useState(false);
    const [pagePath, setPagePath] = useState(true)
    const navigate = useNavigate();
    const handleClose = () => {
      setShow(false)
      localStorage.clear()
      sessionStorage.clear()
      setLoading(false);
    }
   ;
    const [wdata,setwData] = useState([])
    const { register } = useForm();
    const [value1, setValue1] = useState("")
    const [value2, setValue2] = useState("")
    const [value3, setValue3] = useState("")
    const [isloading, setLoading] = useState(false);

    const firstNameFocus = useRef(null);
    const lastNameFocus = useRef(null);
    const emailFocus = useRef(null);
    const contactFocus = useRef(null);
    const passwordFocus = useRef(null);
    const confirmFocus = useRef(null);

    const [getFirstNameColorBorder, setFirstNameColorBorder] = useState("#088B1E")
    const [getLaststNameColorBorder, setLaststNameColorBorder] = useState("#088B1E")
    const [getEmailColorBorder, setEmailColorBorder] = useState("#088B1E")
    const [getContactColorBorder, seContactColorBorder] = useState("#088B1E")
    const [getPassColorBorder, setPassColorBorder] = useState("#088B1E")
    const [getConfPassColorBorder, setConfPassColorBorder] = useState("#088B1E")
    //const lastNameFocus = useRef(null);

    useEffect(
        () => {
          localStorage.clear();
          sessionStorage.clear();
    
            //handleData();
           // forLoop();
            //forLoop2();
        },
        []
    );
    const handleChangeSwitch =(e)=>{
    }

    document.title = "Sign Up | JobSpace - Job Listing Template | Themesdesign";

    const handleShow = () => {
      setShow(true)
    }

    const handleFocus = () => {
      console.log("values.contact")
      console.log(isNaN(parseInt(values.contact)))
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if(values.firstName === undefined){
        firstNameFocus.current?.focus();
        setFirstNameColorBorder("red")
      }
      else if(values.lastName === undefined){
        lastNameFocus.current?.focus();
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("red")
      }
      else if(values.email === undefined){
        emailFocus.current?.focus();
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("red")
      } else if (!pattern.test(values.email)) {
        emailFocus.current?.focus();
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("red")
      }
      else if(values.contact === ""){
        contactFocus.current?.focus();
        seContactColorBorder("red")
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("#088B1E")
      }
      else if(values.contact.length !== 11){
        contactFocus.current?.focus();
        seContactColorBorder("red")
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("#088B1E")
      }
      else if(isNaN(parseInt(values.contact)) === true){
        contactFocus.current?.focus();
        seContactColorBorder("red")
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("#088B1E")
      }
      else if(values.password === ""){
        passwordFocus.current?.focus();
        setPassColorBorder("red")
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("#088B1E")
        seContactColorBorder("#088B1E")
      }
      else if(values.confirm_password === ""){
        confirmFocus.current?.focus();
        setConfPassColorBorder("red")
        setFirstNameColorBorder("#088B1E")
        setLaststNameColorBorder("#088B1E")
        setEmailColorBorder("#088B1E")
        seContactColorBorder("#088B1E")
        setPassColorBorder("#088B1E")
      }
    }
    
  // START SIGNIN MODAL SUBMIT
  const handleSubmitSignin = async() => {
    //console.log("values")
   // setLoading(true);
    handleGetRegister(value1, value3)
    //console.log(wdata)
  };
  
  const handleGetRegister = async() => {
    setLoading(true)
    //console.log("handleGetRegister")
    let filter_data = {
      "isDeleted" : "0", 
      "email"     : value1, 
      "password"  : value3
    }
    
    const Params = {
      "_collection" : "UserCollection",
      "filter_data" : filter_data
    }
    //console.log("Params")
    //console.log(Params)
    await axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

        let dataa = res.data
        //console.log(res)
        //console.log("data")
        //console.log(dataa)
        let newstr2=""
        dataa.UserCollection.map(function(itm,i){
            let strJson = "{" + itm.substring(46, itm.length)
            let strJson2 = itm.substring(18, itm.length)
            newstr2 = strJson2.split("')")[0]
            let json = JSON.parse(strJson.replace(/'/g,'"'));
            json.headerId = newstr2
            dataTmp.push(json)
        })    
        //console.log("dataTmp")
        //console.log(dataTmp)
        //console.log(dataTmp[0].isEmployer)
        
        //console.log(dataTmp[0].userId)
        setwData(dataTmp)  
        let tmptmp = dataTmp[0].userId
        let firstName = dataTmp[0].firstName
        let lastName = dataTmp[0].lastName
        let employer = dataTmp[0].isEmployer
        if(res.status === 200){
          //console.log("res.status")
          //console.log(res.status)
          sessionStorage.setItem("generateOtp", JSON.stringify("test"))
          handleGetCompanyCollection(tmptmp,firstName,lastName,employer) 
          sessionStorage.setItem("userCollection", JSON.stringify(dataTmp))
          sessionStorage.setItem("userCollectionArr", JSON.stringify([dataTmp]))
          sessionStorage.setItem("userdata", JSON.stringify(dataTmp))
          sessionStorage.setItem("userdataArray", JSON.stringify([dataTmp]))
         
        }
    })
    .catch(error =>{
        alert("error: " + "Email or Password are incorrect")
        setLoading(false)
    })
  };

  const handleGetCompanyCollection = async(tmptmp,firstName,lastName,employer) => {
    //console.log(tmptmp)
    let filter_data = {
      "isDeleted" : "0", 
      "userId" : tmptmp,
     /*  "firstName" : firstName,
      "lastName"  : lastName, */
    }
    
    const Params = {
      "_collection" : "CompanyProfile",
      "filter_data" : filter_data
    }
    //console.log("Params")
    //console.log(Params)
    axios
    .post("http://206.189.95.201:8098/action/get", Params)
    .then(res => {

      let dataTmp = []

        let dataaa = res.data
        //console.log("data")
        //console.log(dataaa)
        let newstr2=""
        dataaa.CompanyProfile.map(function(itm,i){
          let strJson = "{" + itm.substring(46, itm.length)
          let strJson2 = itm.substring(18, itm.length)
          newstr2 = strJson2.split("')")[0]
          let json = JSON.parse(strJson.replace(/'/g,'"'));
          json.headerId = newstr2
          dataTmp.push(json)
        })    
        ////console.log("dataTmp")
        sessionStorage.setItem("CompanyProfile", JSON.stringify(dataTmp))
        
      if(employer === "1"){
        const obj = [
          {"name" : "Register Company", "menuId" : "002", "link" : "/#"},
        ]
        const objSign = [
          {"name" : "Signout", "link" : "/signout"},
          {"name" : "Register", "link" : "/signup"},
        ]
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "002"}));
        //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
        
        
        if(pagePath){
          navigate("/")
          setLoading(false)
          setShow(false)
          window.location.reload()
        }
        else{
          return null
        }
      }else {
         
        const obj = [
          {"name" : "View Applicants", "menuId" : "001", "link" : "/JobDetailsDescription"},
          {"name" : "Candidate Shortless", "menuId" : "001", "link" : "/#"},
          {"name" : "Job Posting", "menuId" : "001", "link" : "/#"},
          {"name" : "Employer", "menuId" : "001", "link" : "/CreateJobPosting"},
        ] 
        const objSign = [
          {"name" : "Sign Out", "link" : "/signout"},
          {"name" : "Register", "link" : "/signup"},
        ]
        window.sessionStorage.setItem('results', JSON.stringify({"menuId" : "001"}));
        //window.sessionStorage.setItem('resultsSign', JSON.stringify(objSign));
        
        if(pagePath){
          navigate("/")
          setLoading(false)
          setShow(false)
          window.location.reload()
          
        }
        else{
          return null
        }

      } 
        
    })
    .catch(error =>{
        alert("error: " + error)
    })
  };
  

  // END SIGNIN MODAL SUBMIT
  return (
    <React.Fragment >
     {/*  <TopBar /> */}
       <NavBar />
       <div style={{height : "100px"}}></div>
       <Container style={{width: "500px"}}>
        <Card 
          body
          className="card-border-color-custom"
        >
          <CardTitle tag="h5"  className="text-center">
          Let's Get Started
          </CardTitle>
          <CardText className="text-center" tag="h6">
          Sign Up and get access to all the features of JobSpace.
          </CardText>
          <Form action="/"  onSubmit={handleSubmit} className="mt-5" >
            <FormGroup>
              <div className="form-control-wrap">
                <input
                  variant="outlined"
                  bsize="sm"
                  type="text"
                  //id="name"
                  name="firstName"
                  placeholder="Enter your first name"
                  //ref={register({ required: true })}
                  className="form-control-lg form-control"
                  autoComplete="off"
                  value={values.firstName} 
                  onChange={handleChange}
                  style={{backgroundColor : "transparent", borderWidth : "3px", borderColor : getFirstNameColorBorder}}
                  ref={firstNameFocus}
                  //required
                />
                { errors.firstName && <p className="invalid">{errors.firstName} </p>}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-control-wrap">
                <input
                  bsize="sm"
                  type="text"
                  //id="name"
                  name="lastName"
                  placeholder="Enter your last name"
                  //ref={register({ required: true })}
                  className="form-control-lg form-control"
                  autoComplete="off"
                  value={values.lastName} 
                  onChange={handleChange}
                  style={{backgroundColor : "transparent", borderWidth : "3px", borderColor : getLaststNameColorBorder}}
                  ref={lastNameFocus}
                  //required
                />
                { errors.lastName && <p className="invalid">{errors.lastName} </p>}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-control-wrap">
                <input
                  bsize="sm"
                  //size="sm"
                  //style={{height : "20px", lineHeight : "20px",margin : "-3px", marginRight : "3px"}}
                  type="text"
                  //id="email"
                  name="email"
                  //ref={register({ required: true })}
                  className="form-control-lg form-control"
                  placeholder="Enter your email address"
                  autoComplete="off"
                  value={values.email} 
                  onChange={handleChange}
                  style={{backgroundColor : "transparent", borderWidth : "3px", borderColor : getEmailColorBorder}}
                  ref={emailFocus}
                  //onInputChange={checking}
                  //required
                />
                {errors.email && <p className="invalid">{errors.email}</p>}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-control-wrap">
                <input
                  type="text"
                  bsize="sm"
                  //id="contact"
                  name="contact"
                  placeholder="Enter your mobile number"
                  //ref={register({ required: true })}
                  className="form-control-lg form-control"
                  autoComplete="off"
                  value={values.contact} 
                  onChange={handleChange}
                  numeric
                  style={{backgroundColor : "transparent", borderWidth : "3px", borderColor : getContactColorBorder}}
                  ref={contactFocus}
                  maxLength="11"
                  //required
                />
                {errors.contact && <p className="invalid">{errors.contact}</p>}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState(!passState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type= {passState ? "text" : "password"}
                  //id="password"
                  bsize="sm"
                  name="password"
                  //ref={register({ required: "This field is required" })}
                  placeholder="Enter your password"
                  className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  value={values.password} 
                  onChange={handleChange}
                  style={{backgroundColor : "transparent", borderWidth : "3px", borderColor : getPassColorBorder}}
                  //required
                  ref={passwordFocus}
                />
                {errors.password && <span className="invalid">{errors.password}</span>}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-control-wrap">
                <a
                  href="#confirm_password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPassState1(!passState1);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${passState1 ? "is-hidden" : "is-shown"}`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                </a>
                <input
                  type= {passState1 ? "text" : "password"}
                  //id="confirm_password"
                  name="confirm_password"
                  bsize="sm"
                  //ref={register({ required: "This field is required" })}
                  placeholder="Confirm your password"
                  className={`form-control-lg form-control ${passState1 ? "is-hidden" : "is-shown"}`}
                  value={values.confirm_password} 
                  onChange={handleChange}
                  style={{backgroundColor : "transparent", borderWidth : "3px", borderColor : getConfPassColorBorder}}
                  //required
                  ref={confirmFocus}
                />
                {errors.confirm_password && <span className="invalid">{errors.confirm_password}</span>}
              </div>
            </FormGroup>
            <div className="mb-4">
              <div className="form-check">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  //id="flexCheckDefault"
                  onChange={handleEmployer}
                  value={isEmployer}
                  name="isEmployer"
                  style={{borderWidth : "3px", borderColor : "#088B1E", width : "15px", height : "15px"}}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckDefault"
                  style={{fontWeight : "bold"}}
                >
                  Are you registering as an Employer?{" "}
                  
                </label>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-check">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  //id="flexCheckDefault"
                  onChange={handleCheckBox}
                  value={isChecked}
                  name="isChecked"
                  style={{borderWidth : "3px", borderColor : "#088B1E", width : "15px", height : "15px"}}
                  
                />
                <Label check style={{fontSize : "11px", fontWeight : "bold"}}>
                  By creating your account you agreed to out Privacy Policy the collection, storage and use of your personal data as described in put policy.
                </Label >
              </div>
            </div>
            
            <FormGroup>
              <div className="form-control-wrap">
                <Button
                  type="submit"
                  className="btn-hover-style"
                  disabled={dissableBtn}
                  onClick={handleFocus}
                >
                  
                  {loading ? <Spinner size="lg" color="light" /> : "Sign Up"}
                </Button>
              </div>
            </FormGroup>
          </Form>
          <div className="text-center">
            <p className="mb-0">
              Already a member ?{" "}
              <Link
                onClick={handleShow}
                className="fw-medium text-decoration-underline"
              >
                Sign In
              </Link>
            </p>
          </div>
        </Card>
      <div
        className="modal fade"
        id="datapolicy"
        tabIndex="-1"
        aria-labelledby="datapolicy"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="modal-body p-5" style={{backgroundColor: "#F0FEED"}}>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <Row>
                <h9 style={{color: "#00000", fontSize : 16, fontWeight: "bolder", textAlign: "center"}}>
                  DATA PRIVACY POLICY
                </h9>
              </Row>
              <p style={{marginTop: "6%", textAlign:"justify"}}>
                We believe you should be able to access and control your personal information no matter where you live. Depending on how you use JobSpace, you may have the
                right to request access to, correct, amend, delete, restrict, or object to certain uses of your personal information.

                <div style={{marginTop: "3%"}}></div>
                We will not charge you more or provide you with a different level of service if you exercise any of these rights, Please note that a number of these rights
                apply only in certain circumstances, and all of these rights may be limited by law.

                <div style={{marginTop: "3%"}}></div>
                We will retain your personal data only as long as necessary to fulfill the purposes for which we have collected it. To determine the appropriate retension period,
                we consider the amount, nature and sensitivity of your personal data, the potential risk of harm from unauthorized use or disclosure of your personal data,
                the purposes for which we process your personal data and whether we can achieve those purposes through other means and the applicable legal requirements.

                <div style={{marginTop: "3%"}}></div>
                We will also retain and use your personal information to extent necessary to comply with our legal obligations, resolve disputes and enforce our policies.

                <div style={{marginTop: "3%"}}></div>
                If you stop using our services or if you delete your account with us, we will delete your information or store your information in an aggregated and anonymized format.

              </p>
              <Row>
                <Col sm={3}>
                </Col>
                <Col sm={3}>
                  <label style={{fontSize: 18, fontWeight : "bolder", marginTop: "3%"}}>
                    I Agree
                  </label>
                </Col>
                <Col sm={5}>
                  <Switch
                    checked={true}
                    onChange={handleChangeSwitch}
                    // onClick={()=>handleClickSwitch()}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Col>
                <Col sm={1}>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>

        <Modals show={show} onHide={handleClose} centered size="sm">
          <Modals.Header closeButton
          >
            <Modals.Title style={{fontSize : "15px", fontWeight : "900"}}>Sign in</Modals.Title>
          </Modals.Header>
          <Form action="/" className="auth-form">
            <Modals.Body>
              <div className="mb-3">
                <label
                  htmlFor="usernameInput"
                  className="form-label"
                >
                  Username
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="usernameInput"
                  placeholder="Enter email address"
                  required
                  onChange={event => setValue1(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="passwordInput"
                  className="form-label"
                >
                  Password
                </label>
                <Input
                  type="password"
                  className="form-control"
                  id="passwordInput"
                  placeholder="Enter your password"
                  onChange={event => setValue3(event.target.value)}
                  required
                />
              </div>
            </Modals.Body>
            <div className="mb-4 ">
              
              <div className="form-check">
              
                <input
                  className=" "
                  type="checkbox"
                  id="flexCheckDefault"
                />&nbsp;&nbsp;
                <Link to="/resetpassword" className="float-end ">
                Forgot Password?&nbsp;&nbsp;&nbsp;&nbsp;
                </Link>
                <label className="form-check-label" htmlFor="flexCheckDefault" >
                Remember me
                </label>
              </div>
            </div>
          
          
            <Modals.Footer>
              <ButtonSignin variant="secondary" onClick={handleClose}>
                Close
              </ButtonSignin>
              <ButtonSignin variant="success" onClick={handleSubmitSignin}>
              {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
              </ButtonSignin>
            
            </Modals.Footer>
          </Form>
        </Modals>
       </Container>
       <ScrolltoTop />
    </React.Fragment>
  );
};



export default FormSignup;